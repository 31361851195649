import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { HTTP } from '@ionic-native/http/ngx';
import { Platform } from '@ionic/angular';
import { environment } from "src/environments/environment";


@Injectable()
export class HttpService {
  apiAdress: string = environment.apiAddress + "/api/";
  constructor(private http: HTTP, private httpClient: HttpClient, private platform: Platform) {

    this.http.setHeader('*', 'Access-Control-Allow-Origin', '*');
    this.http.setHeader('*', 'Access-Control-Allow-Methods', 'POST, GET, OPTIONS, PUT');
    this.http.setHeader('*', 'Accept', 'application/json');
    this.http.setHeader('*', 'content-type', 'application/json');
    this.http.setDataSerializer('json');


  }


  //TODO: guard eklenicek login için
  post(url: string, body: any): Promise<any> {
    return this.platform.ready().then(() => {
      var fullUrl: string = this.apiAdress + url;

      if (window.location.href.match("sedna360mobileb2c")) {
        return this.httpClient.post<Promise<any>>(fullUrl, body).toPromise();
      } else {
        if (this.platform.is("mobileweb") || this.platform.is("desktop")) {
          return this.httpClient.post<Promise<any>>(fullUrl, body).toPromise();
        }
        else {
          console.log("native girdi", this.platform.platforms())
          var fullUrl: string = this.apiAdress + url;
          return new Promise((resolve, reject) => {
            this.http.setDataSerializer('json');
            this.http.post(fullUrl, body, {}).then(res => {
              resolve(JSON.parse(res.data));
            }).catch(err => {
              reject(err);
              // alert("post error "+JSON.stringify(err))
              console.log("buradayım", err)
            });
          });
        }
      }
    });

  }

  getWeather(lat, lon): Promise<any> {
    // alert(JSON.stringify(this.platform.platforms()))
    if (window.location.href.match("sedna360mobileb2c")) {
      // alert("lat: "+lat+ " lon: "+lon+"if url match içine girdi")
      return this.httpClient.get<Promise<any>>(
        'https://api.openweathermap.org/data/2.5/onecall?lat=' + lat + '&lon=' + lon + '&units=Metric&appid=880d34bec6bbad7bc7b8c73c58118dbe').toPromise().catch(err => {
          // alert(lat+lon+"if url match içine girdi, ve err düştü "+ JSON.stringify(err))
        })

    } else {
      if (this.platform.is("mobileweb") || this.platform.is("desktop")) {
        // alert("lat: "+lat+ " lon: "+lon+"if platform desktop yada mobileweb içine girdi")
        return this.httpClient.get<Promise<any>>(
          'https://api.openweathermap.org/data/2.5/onecall?lat=' + lat + '&lon=' + lon + '&units=Metric&appid=880d34bec6bbad7bc7b8c73c58118dbe').toPromise().catch(err => {
            // alert(lat+lon+"if platform desktop yada mobileweb içine girdi ve err düştü"+JSON.stringify(err))
          })
      } else {
        // alert("lat: "+lat+ " lon: "+lon+"else mobile için native http")
        return new Promise((resolve, reject) => {
          this.http.setDataSerializer('json');
          //cors-anywhere.herokuapp.com/http:  bunu ekledik çünkü cors hatası alıyoduk
          this.http.get('https://cors-anywhere.herokuapp.com/http://api.openweathermap.org/data/2.5/onecall?lat=' + lat + '&lon=' + lon + '&units=Metric&appid=880d34bec6bbad7bc7b8c73c58118dbe', {}, {}).then(res => {
            resolve(JSON.parse(res.data));
          })
            .catch(err => {
              reject(err);
              alert("lat: " + lat + " lon: " + lon + " mobile native get weather err düştü" + JSON.stringify(err))
            });
        });
      }
    }
  }
}
