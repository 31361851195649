import { Component, Input, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { SharedService } from 'src/app/services/shared.service';

const ZOOM_STEP: number = 0.25;
const DEFAULT_ZOOM: number = 1;

@Component({
  selector: 'app-pdf-viewer',
  templateUrl: './pdf-viewer.page.html',
  styleUrls: ['./pdf-viewer.page.scss'],
})
export class PdfViewerPage implements OnInit {
  pdfSrc: any;
  title: string;
  zoom: number = 1.0;
  originalSize: boolean = true;
  public pdfZoom: number = DEFAULT_ZOOM;
  @Input() data;
  constructor(
    private shared: SharedService,
    public modalController: ModalController
  ) {
    this.shared.iframeLinksTriggerObservable.subscribe(response => {
      this.title = response.Remark;
      this.pdfSrc = response.Link;
    });
  }

  ngOnInit() {
    console.log(this.data)
  }

  incrementZoom(amount: number) {
    this.zoom += amount;
  }

  dismiss() {
    this.modalController.dismiss({
      'dismissed': true
    });
  }

  public zoomIn()
	{
		this.pdfZoom += ZOOM_STEP;
	}

	public zoomOut()
	{
		if (this.pdfZoom > DEFAULT_ZOOM) {
			this.pdfZoom -= ZOOM_STEP;
		}
	}

	public resetZoom()
	{
		this.pdfZoom = DEFAULT_ZOOM;
	}
}
