export class MealDTO {
    MenuName: string;
    ProductName: string;
    Amount: number | null;
    Price: number| null;

    ActivityActionId:number | null;
    FoodId:number | null;
    FixFood:number | null;
    Priced: number | null;
    FoodPrice: number | null;
    FoodPriced: number | null;
    CompanyRecId: number | null;
}