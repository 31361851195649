import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class SharedService {
  private content = new BehaviorSubject<Array<any>>([])
  private bottomTabBarBooleanVariable = new BehaviorSubject<any>({})
  private fastMenuReservationCounter = new BehaviorSubject<any>([])
  private fastMenuTraceCounter = new BehaviorSubject<any>([])
  private taskItem = new BehaviorSubject<any>([])
  private threeD_HTML = new BehaviorSubject<any>([])
  private optionTitle = new BehaviorSubject<any>([])
  private groupOtelLanguageTrigger = new BehaviorSubject<any>([])
  private iframeLinksTrigger = new BehaviorSubject<any>([])
  private isLoginTrigger = new BehaviorSubject<any>([])
  private favoritedPagesUptadeTrigger = new BehaviorSubject<any>([])
  private sssUptadeTrigger = new BehaviorSubject<any>([])
  private paymentRedirecTrigger = new BehaviorSubject<any>([])
  private balanceRedirecTrigger = new BehaviorSubject<any>([])
  private customToolbarTranslateTrigger = new BehaviorSubject<any>([])

  public share = this.content.asObservable();
  public basket = this.content.asObservable();
  public buttomTabBarBoolean = this.bottomTabBarBooleanVariable.asObservable();
  public ReservationCounter = this.fastMenuReservationCounter.asObservable();
  public traceCounter = this.fastMenuTraceCounter.asObservable();
  public taskItemObserbavle = this.taskItem.asObservable();
  public threeD_HTMLObservable = this.threeD_HTML.asObservable();
  public optionTitleObservable = this.optionTitle.asObservable();
  public groupOtelLanguageTriggerObservable = this.groupOtelLanguageTrigger.asObservable();
  public iframeLinksTriggerObservable = this.iframeLinksTrigger.asObservable();
  public isLoginTriggerObservable = this.isLoginTrigger.asObservable();
  public favoritedPagesUptadeObservable = this.favoritedPagesUptadeTrigger.asObservable();
  public sssUptadeObservable = this.sssUptadeTrigger.asObservable();
  public paymentRedirecTriggerObservable = this.paymentRedirecTrigger.asObservable();
  public balanceRedirecTriggerObservable = this.balanceRedirecTrigger.asObservable();
  public customToolbarTranslateTriggerObservable = this.customToolbarTranslateTrigger.asObservable();

  constructor() {
  }
  updatecustomToolbarTranslateTrigger(data){
    this.customToolbarTranslateTrigger.next(data)
  }
  updateBalanceRedirecTrigger(data){
    this.balanceRedirecTrigger.next(data)
  }
  updatePaymentRedirecTrigger(data){
    this.paymentRedirecTrigger.next(data)
  }
  updateSSS(data){
    this.sssUptadeTrigger.next(data)
  }
  updateIsLogin(data){
    this.isLoginTrigger.next(data)
  }
  updatefavoritedPages(data){
    this.favoritedPagesUptadeTrigger.next(data)
  }
  updateIframeLink(data) {
    this.iframeLinksTrigger.next(data)
  }
  updateData(data) {
    this.content.next(data);
  }
  updateBottomTabBarVisibility(data) {
    this.bottomTabBarBooleanVariable.next(data)
  }
  updateFastMenuReservationCounter(data) {
    this.fastMenuReservationCounter.next(data)
  }
  updateFastMenuTraceCounter(data) {
    console.log("shared servicte nextliyo", data)
    this.fastMenuTraceCounter.next(data)
  }
  //task sayfalarına item objesini gönderebilmek için yapıldı. Y.E
  updateTaskItem(data) {
    this.taskItem.next(data)
  }
  update3DHTML(data) {
    this.threeD_HTML.next(data);
  }
  uptadeTitleForOption(data) {
    this.optionTitle.next(data);
  }
  updateGroupOtelLanguage(data) {
    console.log("ss")
    this.groupOtelLanguageTrigger.next(data)
  }
}
