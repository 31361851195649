import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { DomSanitizer } from '@angular/platform-browser';
import { ModalController } from '@ionic/angular';
import { SharedService } from 'src/app/services/shared.service';
import { StorageService } from 'src/app/services/storage.service';

@Component({
  selector: 'app-advertisement-modal',
  templateUrl: './advertisement-modal.page.html',
  styleUrls: ['./advertisement-modal.page.scss'],
  encapsulation: ViewEncapsulation.None
})
export class AdvertisementModalPage implements OnInit {
  title: any;
  link: any;
  remark: any;

  constructor(
    private shared: SharedService,
    private domSanitazer: DomSanitizer,
    private storageService: StorageService,
    public modalController: ModalController,
    public dialogRef: MatDialogRef<AdvertisementModalPage>) {
    this.shared.iframeLinksTriggerObservable.subscribe(res => {
      this.title = res.Remark
      this.link = this.domSanitazer.bypassSecurityTrustResourceUrl(res.Link)
    })
  }

  ngOnInit() {
    this.storageService.getItem("anaMenu").then(anaMenu=>{
      if(anaMenu.MobileHotelDefinition.AdvertiseUrl){
        this.remark = this.domSanitazer.bypassSecurityTrustHtml(anaMenu.MobileHotelDefinition.AdvertiseUrl) 
      }
    })
    console.log(this.link, this.title)
  }

  dismiss() {
    this.dialogRef.close();
  }
}
