import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ModalController } from '@ionic/angular';
import { ModalTpyes } from '../models/enums/modal-types.enum';
import { AdvertisementModalPage } from '../pages/advertisement-modal/advertisement-modal.page';
import { BasketPage } from '../pages/basket/basket.page';
import { IframerPage } from '../pages/iframer/iframer.page';
import { KvkkPage } from '../pages/kvkk/kvkk.page';
import { LoginPage } from '../pages/login/login.page';
import { PaymentPage } from '../pages/payment/payment.page';
import { RatingModalPage } from '../pages/rating-modal/rating-modal.page';
import { Screen3DPage } from '../pages/screen3-d/screen3-d.page';
import { WeatherPage } from '../pages/weather/weather.page';
import { DoorLockPage } from '../pages/door-lock/door-lock.page';
import { EmptyScreenComponent } from '../pages/components/empty-screen/empty-screen.component';
import { DoorLockLoginPage } from '../pages/door-lock-login/door-lock-login.page';
import { PdfViewerPage } from '../pages/pdf-viewer/pdf-viewer.page';
import { QRCodePage } from '../pages/qrcode/qrcode.page';

@Injectable({
  providedIn: 'root'
})
export class ModalControllerService {
  modal
  constructor(public modalController: ModalController,
    public dialog: MatDialog,) { }

  async presentVideoLinkModal(videoLink) {
    console.log("3D modal")
    this.modal = await this.modalController.create({
      component: KvkkPage,
      cssClass: 'my-custom-class',
      componentProps: {
        'type': 'videoLink',
        'videoLink': videoLink
      }
    });
    return await this.modal.present();
  }

  async ShowModal(componentObj:any,datas:any)
  {
    
     const modal = this.modalController.create({
        component:componentObj,
        componentProps:{
          'dataModel':datas
        }
     });

     return modal;
  }

  async presentModal(modalType: ModalTpyes, data) {
    switch (modalType) {

      case ModalTpyes.rating:
        console.log("3D modal")
        this.modal = await this.modalController.create({
          component: RatingModalPage,
          cssClass: 'ratingModalCSS',
          componentProps: {
            'data': data
          }
        });
        return await this.modal.present();
        break;

      case ModalTpyes.advertisement:
        console.log("3D modal")
        this.modal = this.dialog.open(AdvertisementModalPage, {
          panelClass: 'advertisementClass',
          hasBackdrop: true
        });
        break;

      case ModalTpyes.videoLink:
        console.log("3D modal")
        this.modal = await this.modalController.create({
          component: KvkkPage,
          cssClass: 'my-custom-class',
          componentProps: {
            'type': 'videoLink',
            'videoLink': data
          }
        });
        return await this.modal.present();
        break;

      case ModalTpyes.internetLink:
        this.modal = await this.modalController.create({
          component: IframerPage,
          cssClass: 'my-custom-class'
        });
        return await this.modal.present();
        break;

      case ModalTpyes.preCheckinLink:
      case ModalTpyes.sedna360Survey:
        this.modal = await this.modalController.create({
          component: IframerPage,
          cssClass: 'my-custom-class'
        });
        return await this.modal.present();
        break;

      case ModalTpyes.pdf:
        this.modal = await this.modalController.create({
          component: PdfViewerPage,
          cssClass: 'my-custom-class',
          componentProps: {
            'data': data
          }
        });
        return await this.modal.present();
        break;
        
      case ModalTpyes.weather:
        this.modal = await this.modalController.create({
          component: WeatherPage,
          cssClass: 'my-custom-class'
        });
        return await this.modal.present();
        break;

      case ModalTpyes.basket:
        this.modal = await this.modalController.create({
          component: BasketPage,
          cssClass: 'my-custom-class'
        });
        return await this.modal.present();
        break;

      case ModalTpyes.payment:
        console.log("payment modal")
        this.modal = await this.modalController.create({
          component: PaymentPage,
          cssClass: 'my-custom-class'
        });
        return await this.modal.present();
        break;

      case ModalTpyes.threeD_HTML:
        console.log("3D modal")
        this.modal = await this.modalController.create({
          component: Screen3DPage,
          cssClass: 'my-custom-class'
        });
        return await this.modal.present();
        break;

      case ModalTpyes.kvkk:
        console.log("3D modal")
        this.modal = await this.modalController.create({
          component: KvkkPage,
          cssClass: 'kvkkModalClass',
          componentProps: {
            'type': 'kvkk',
          }
        });
        return await this.modal.present();
        break;

      case ModalTpyes.doorLock:
        console.log("3D modal")
        this.modal = await this.modalController.create({
          component: DoorLockPage,
          cssClass: 'doorLockModalClass',
          componentProps: {
            'type': 'doorlock',
          }
        });
        return await this.modal.present();
        break;

      case ModalTpyes.termsAndConditions:
        console.log("3D modal")
        this.modal = await this.modalController.create({
          component: KvkkPage,
          cssClass: 'kvkkModalClass',
          componentProps: {
            'type': 'terms',
          }
        });
        return await this.modal.present();
        break;
      case ModalTpyes.networkPage:
        const networkModel= {
          background: 'assets/imgs/12.png',
          title: 'No Connection',
          subtitle: 'Your internet connection was',
          description: 'interrupted, Please retry',
          titleColor: 'dark',
          color: 'medium',
          button: 'RETRY',
          buttonColor: 'dark',
          centerAlign: true
        }
        const modal = await this.modalController.create({
          component: EmptyScreenComponent,
          componentProps:{
            'model':networkModel
          }
        });
         modal.present();
         return await modal.onWillDismiss();
        break;
      case ModalTpyes.login:
        console.log("login page modal")
        this.modal = await this.modalController.create({
          component: LoginPage,
          cssClass: 'loginModalClass'
        });
        this.modal.present();
        return await this.modal.onDidDismiss().then(data => {
          console.log('dismissed', data);

        });

        break;

      case ModalTpyes.QRCode:
        console.log("QR Code Modal");
        this.modal = await this.modalController.create({
          component: QRCodePage,
          componentProps: {
            'data': data
          }
        });
        return await this.modal.present();

      default:
        break;
    }
  }
}
