import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ModalController } from '@ionic/angular';
import { DoorLockLoginCheckService } from 'src/app/services/doorLockLoginCheck.service';
import { Location } from '@angular/common';
import { AlertController } from '@ionic/angular';
import { StorageService } from 'src/app/services/storage.service';
import { ToasterService } from 'src/app/services/toaster.service';

@Component({
  selector: 'app-door-lock-login',
  templateUrl: './door-lock-login.page.html',
  styleUrls: ['./door-lock-login.page.scss'],
})
export class DoorLockLoginPage implements OnInit {
  loginForm: FormGroup;
  signUpForm: FormGroup;
  model: any
  confirmed: boolean
  varMi: boolean
  signUp: boolean = false;
  activationCode: string = '';
  activationCodeResponse: string = '';
  showActivationAlert: boolean = true; // Alert'ın görünürlüğünü kontrol etmek için bir değişken
  isActivationCodeEntered: boolean = false; // Aktivasyon kodu girildi mi kontrolü
  companyId: string;
  guestId: number;

  constructor(
    private formBuilder: FormBuilder,
    private location: Location,
    private modalController: ModalController,
    private doorLockLoginCheckService: DoorLockLoginCheckService,
    private alertController: AlertController,
    private storageService: StorageService,
    private toast: ToasterService) {

    this.storageService.getItem("anaMenu").then(response => {
      this.companyId = response.MobileHotelDefinition.CompaniesId;
    }).catch(err => {
      console.log(err);
    });

    this.storageService.getItem("profile").then(response => {
      this.guestId = response.guestId
    }).catch(err => {
      console.log(err);
    });

    this.loginForm = this.formBuilder.group({
      email: ["", [Validators.required, Validators.email]],
      password: ["", [Validators.required, Validators.minLength(6)]],
    });

    this.signUpForm = this.formBuilder.group({
      email: ["", [Validators.required, Validators.email]],
      password: ["", [Validators.required, Validators.minLength(6)]],
      confirmPassword: ["", [Validators.required, Validators.minLength(6)]]
    });
  }

  ngOnInit() {
  }

  onSubmit() {
    if (this.loginForm.valid) {
      const email = this.loginForm.get('email').value;
      const password = this.loginForm.get('password').value;

      this.model = {
        "email": email,
        "password": password
      }

      this.doorLockLoginCheckService.getProfileSearch(this.model).then((response) => {
        debugger;
        if (response.ResponseType === 'Ok') {
          this.confirmed = true;
          this.toast.toast("Giriş işlemi başarılı", "success", 2000)
          this.dismiss();
        }
        else {
          this.confirmed = false;
          alert("Email veya şifre yanlış.")
          this.dismiss();
        }
      }).catch(err => {
        alert(JSON.stringify(err));
      });
    }
  }

  registerButton() {
    this.signUp = true;
  }

  async showActivationCodeInput() {
    const alert = await this.alertController.create({
      header: 'Aktivasyon Kodu Girişi',
      inputs: [
        {
          name: 'activationCode',
          type: 'text',
          value: this.activationCode,
          placeholder: 'Aktivasyon Kodu',
        }
      ],
      buttons: [
        {
          text: 'İptal',
          role: 'cancel',
          handler: () => {
            // İptal işlemi
          }
        },
        {
          text: 'Tamam',
          handler: (data) => {
            this.activationCode = data.activationCode;
            if (this.activationCode.length === 8) {
              this.isActivationCodeEntered = true; // Aktivasyon kodu girildiyse
              // Kod 8 karakterse ve activationcodelar eşleşiyor ise burada kodu backend'e gönder
              if (this.activationCodeResponse === this.activationCode) {
                debugger;
                const email = this.signUpForm.get('email').value;
                const password = this.signUpForm.get('password').value;

                this.model = {
                  "email": email,
                  "password": password,
                  "guestId": this.guestId,
                  "companyId": this.companyId
                }

                this.doorLockLoginCheckService.registerGuest(this.model).then(response => {
                  if (response.ResponseType === 0) {
                    // kayıt başarılı ise işlem yap
                    // this.confirmed = true;
                    this.toast.toast("Kayıt işlemi başarılı", "success", 2000)
                    this.dismiss();
                  }
                  else{
                    // this.confirmed = false;
                    this.toast.toast("Bir hata ile karşılaşıldı.", "error", 2000)
                    this.dismiss();
                  }
                }).catch(err => {
                  console.log(err);
                });
              }
              else{
                this.showActivationCodeMismatchAlert();
              }
            } else {
              this.showLengthAlert(); // Kod 6 karakterden farklı ise alert göster
            }
          }
        }
      ]
    });

    await alert.present();
  }

  async onSubmitSignUp() {

    if (this.signUpForm.valid) {

      const email = this.signUpForm.get('email').value;
      const password = this.signUpForm.get('password').value;
      const confirmPassword = this.signUpForm.get('confirmPassword').value;

      if (password === confirmPassword) {

        this.model = {
          "email": email,
          "password": password,
          "passwordCheck": confirmPassword,
          "companyId": this.companyId,
          "companyMailDomain": '',
          "activationCode": ''
        }

        this.doorLockLoginCheckService.sendActivationCode(this.model).then(response => {
          if (response !== undefined && response !== null) {
            //debugger;
            this.activationCodeResponse = response.ActivationCode; // TODO: Encrypt edilerek getirilmesi gerekiyor. Daha sonra yapılacaktır.
          }
        }).catch(err => {
          console.log(err);
        });

        this.showActivationCodeInput(); // Kullanıcı aktivasyon kodunu girmemişse, aktivasyon kodu girişi için olan alert'ı göster
      }
      else {
        this.showPasswordMismatchAlert();
      }
    }
  }

  async showActivationCodeMismatchAlert() {
    const alert = await this.alertController.create({
      header: 'Uyarı',
      message: 'Aktivasyon kodu yanlış, lütfen tekrar deneyiniz.',
      buttons: [{
        text: 'Tamam',
        handler: () => {
          this.showActivationCodeInput();
        }
      }]
    });

    await alert.present();
  }

  async showPasswordMismatchAlert() {
    const alert = await this.alertController.create({
      header: 'Uyarı',
      message: 'Şifreler uyuşmuyor. Lütfen şifreyi doğru girin.',
      buttons: ['Tamam']
    });

    await alert.present();
  }

  async showLengthAlert() {
    const alert = await this.alertController.create({
      header: 'Uyarı',
      message: 'Aktivasyon kodu 8 karakter olmalıdır.',
      buttons: [
        {
          text: 'Tamam',
          handler: () => {
            this.showActivationCodeInput();
          }
        }
      ]
    });

    await alert.present();
  }

  dismiss() {
    this.modalController.dismiss({
      'dismissed': true,
      'datas': this.confirmed
    });
  }

  backClicked() {
    this.location.back();
  }
}
