import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { HttpService } from 'src/app/services/http.service';
import { StorageService } from 'src/app/services/storage.service';
import { LoadingService } from 'src/app/services/loading.service';
import { ToasterService } from 'src/app/services/toaster.service';
import { ReservationService } from 'src/app/services/reservation.service';
import { SystemService } from 'src/app/services/system.service';
import { TranslateService } from '@ngx-translate/core';
import { RouteLinkParametersService } from 'src/app/services/route-link-parameters.service';
import { MealDTO } from 'src/app/models/reservation/MealDTO';


@Component({
  selector: 'app-alakart-modal',
  templateUrl: './alakart-modal.page.html',
  styleUrls: ['./alakart-modal.page.scss'],
})

export class AlakartModalPage implements OnInit {
  hotelID
  CompaniesId
  CompanyId
  FolioId
  rezID
  isDisabled: boolean;
  firstname
  lastname
  roomNumber
  PosType
  checkInDate
  activityDetail : MealDTO[] = [];
  // paxCount: number;
  // babyCount: number;
  // childPaidCount: number;
  // childFreeCount: number;
  // childCount: number;
  calculatedPrice: number;
  alacartFoodPrice : number = 0;
  guestID: any;
  constructor(@Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<AlakartModalPage>,
    private service: HttpService,
    private translate: TranslateService,
    private storageService: StorageService,
    private loading: LoadingService,
    private reservationService: ReservationService,
    private toast: ToasterService,
    private routelink: RouteLinkParametersService,
    private systemService: SystemService) { }
    

  ngOnInit() {
    console.log(this.data)
    var personCountResponse = this.reservationService.calculatePersonCountToTitle(this.data.name);
    
    //FIXME: Bu kullanım sağlıklı değil düzeltilmesi gerekiyor. Aciliyetten yapıldı.
    if (this.data.dateModel.UseFoodPrice == 0) {
      if(this.data.dateModel.Available == 0 && this.data.dateModel.Open_Price_Control == 0){
        this.calculatedPrice = 0;
      }
      else{
        if (this.data.dateModel.Open_Price_Control == 0) {
          this.calculatedPrice = 0;
        }
        else if (this.data.dateModel.PriceType == 1) {
          this.calculatedPrice = this.data.dateModel.UnitPrice;
        }
        else {
          this.calculatedPrice = (personCountResponse.PaxCount * this.data.dateModel.Price) + (personCountResponse.ChildPaidCount * this.data.dateModel.ChildPrice);
        }
      }
    }
    else if(this.data.dateModel.Available == 0 && this.data.dateModel.Open_Price_Control == 0){
      this.calculatedPrice = 0;
    }
    else{
      this.calculatedPrice = (personCountResponse.PaxCount * this.data.dateModel.Price) + (personCountResponse.ChildPaidCount * this.data.dateModel.ChildPrice);
    }
    
    
    this.storageService.getItem("anaMenu").then(res => {
      this.hotelID = res.MobileHotelDefinition.RecId
      this.CompaniesId = res.MobileHotelDefinition.CompaniesId
      this.CompanyId = res.MobileHotelDefinition.CompanyId
    })



    this.storageService.getItem("profile").then(res => {
      this.FolioId = res.FolioId
      this.guestID = res.guestId
      this.PosType = res.PosType
      this.rezID = res.reservationId
      this.firstname = res.firstname
      this.lastname = res.lastname
      this.roomNumber = res.roomNumber
      this.checkInDate = res.checkinDate
    })

    if (this.data.activityMenus.length > 0) {
      
      this.data.activityMenus.forEach(element => {
          if (element.ActivityFoods.length> 0) {
            const activityFoods = element.ActivityFoods.filter(x => x.Amount !== 0)
            
            activityFoods.forEach(product => {

              let temp : MealDTO = new MealDTO();
              temp.MenuName = element.Menu,
              temp.ProductName = product.Food,
              temp.Amount = product.Amount,
              temp.Price = product.NetAmount

              //temp.ActivityActionId = Bu kısımdaki işlemler
              temp.FoodId = product.RecId;
              temp.Amount = product.Amount;
              temp.Price = product.NetAmount;
              temp.FixFood = product.FixFood;
              temp.Priced = product.Priced;
              temp.FoodPrice = product.Price;
              temp.FoodPriced = product.Priced;
              temp.CompanyRecId = product.CompanyRecId


              if(temp.Price > 0 ){
                if (this.data.dateModel.UseFoodPrice) {
                  this.alacartFoodPrice  = this.alacartFoodPrice + temp.Price; 
                }
              }
              this.activityDetail.push(temp);
              
            });
          }
      });
    }

  }
  onNoClick(): void {
    this.dialogRef.close();
  }

  convertToTime(date: string): string{
    if(date){
      const splitDate: string[] = date.split('T');
      if(splitDate.length>0){
        //return splitTime[1].substring(0,5);
        const splitDatePart: string[] = splitDate[0].split('-');

        return splitDatePart[2] + '.' + splitDatePart[1] + '.' + splitDatePart[0]
      }
      return null;
    }
    return null;
  }

  saveReservation() {
    this.isDisabled = true
    this.loading.show().then(res => {
      var personCountResponse = this.reservationService.calculatePersonCountToTitle(this.data.name);
      if (this.data.dateModel.Open_Price_Control == 0) {
        this.calculatedPrice = 0;
      }
      else if (this.data.dateModel.PriceType == 1) {
        this.calculatedPrice = this.data.dateModel.UnitPrice;
      }
      else {
        this.calculatedPrice = (personCountResponse.PaxCount * this.data.dateModel.Price) + (personCountResponse.ChildPaidCount * this.data.dateModel.ChildPrice);
      }

      var activityProcess = {
        ActivityId: this.data.TableObjectToSend.ActivityId,
        ActivityName: ''
      };
      var tableProcess = {
        TableId: this.data.TableObjectToSend.TableId,
        TableName: ''
      };
      var getObject = {
        BlockageType: 'Table',
        ReservationDate: this.data.TableObjectToSend.SelectedDate,
        ActivityProcess: activityProcess,
        TableProcess: tableProcess,
        ClientType: 'Mobile',
        // CompanyId: this.CompanyId,
        HotelId: this.hotelID
      };

      this.service.post("v1/SednaMobileB2C/CheckBlokageTable", getObject).then(res => {
        console.log("checkBlockaj ", res)

        if (res.ResponseType == "Ok") {
          var postObject = {
            ReservationId: this.rezID,
            GuestId: this.guestID,
            ReservationDate: this.data.TableObjectToSend.SelectedDate,
            BlockageType: 'Table',
            ActivityProcess: activityProcess,
            TableProcess: tableProcess,
            ClientType: 'Mobile',
            HotelId: this.hotelID
          };
          this.service.post("v1/SednaMobileB2C/SaveBlockageTable", postObject).then(res => {
            console.log("save blockage ", res)
            if (res.ResponseType == "Ok") {
              this.service.post("v1/SednaMobileB2C/SaveReservationFromPms", {
                "HotelId": this.hotelID,
                "CompanyRecId": this.CompanyId,
                "CompanyId": this.CompaniesId,
                "FolioId": this.FolioId,
                "CheckInDate": this.systemService.dateConverter(this.checkInDate),
                "DeviceVersion": "2.4.10.80",
                "KioskPost": {
                  "KioskReservation": {
                    "ActivityId": this.data.TableObjectToSend.ActivityId,
                    "RezId": this.rezID,
                    "Pax": personCountResponse.PaxCount,
                    "Child": personCountResponse.ChildPaidCount,
                    "GuestName": this.firstname + ' ' + this.lastname,
                    "StartTime": this.data.dateModel.OpeningTime,
                    "EndTime": this.data.dateModel.ClosingTime,
                    "Price": this.calculatedPrice + this.alacartFoodPrice,
                    "Amount": this.calculatedPrice + this.alacartFoodPrice,
                    "NetAmount": this.calculatedPrice + this.alacartFoodPrice,
                    "ClientType": 3
                    // "Room2": this.roomNumber
                  },
                  "KioskFolio": {
                    "PosType": this.PosType

                  },
                  "MenuSelect": this.data.menuSelect,
                  "KioskFoods":{
                    "BasketIsEmpty": this.data.menuSelect == 1 ? 1: 0 ,
                    "MealsInBasket" :this.activityDetail
                  }
                },
                "SelectedDate": {
                  "CurrType": this.data.dateModel.CurrType,
                  "DepartCode": this.data.dateModel.DepartCode,
                  "RevenueGroup": this.data.dateModel.RevenueGroup,
                  "SelectedDate": this.data.date,
                  "Open_Price_Control": this.data.dateModel.Open_Price_Control,
                  "ChildPrice": this.data.dateModel.ChildPrice,
                  "FolioNotCharge": this.data.dateModel.FolioNotCharge,
                  "Price": this.data.dateModel.Price,
                  "VatRatio": this.data.dateModel.VatRatio,
                  "UseFolioActivityDate": this.data.dateModel.UseFolioActivityDate
                },
                "Persons": this.data.name,
                "Table": {
                  "TableId": this.data.TableObjectToSend.TableId,
                  "Remark": this.data.TableObjectToSend.Remark,
                },
                "MobileReservationNote": this.data.reservationNote
              }).then(resp => {

                this.storageService.setFastMenuReservationCounterToCache(resp)

                if (resp.Response.GeneralResponseType == "Success") {
                  this.translate.get("toastTranslations.reservationSuccess").subscribe(successResult => {
                    this.toast.toast(successResult, "success", 3000)
                    this.loading.hide();
                    this.routelink.getRouteLinkParameters("/dashboard", {})
                  })
                } else {
                  this.translate.get("toastTranslations.reservationFail").subscribe(successFail => {
                    this.toast.toast(successFail, "fail", 3000)
                    this.loading.hide()
                  })
                }
                this.onNoClick()
                this.loading.hide();
              }).catch(err => {
                console.log('err: ', err);
                this.isDisabled = false
              })
            }
          }).catch(err => {
            console.log('err: ', err);
            this.isDisabled = false
          })
        } else {
          this.translate.get("toastTranslations.tableInUse").subscribe(successResult => {
            this.toast.toast(successResult, "fail", 3000)
            this.loading.hide()
          })
        }
      }).catch(err => {
        console.log('err: ', err);
        this.isDisabled = false
      })
    })
  }
}
