import { Component, Input, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { ModalController } from '@ionic/angular';
import { EnumLanguageType } from 'src/app/models/enums/enum-language-type.enum';
import { StorageService } from 'src/app/services/storage.service';

@Component({
  selector: 'app-kvkk',
  templateUrl: './kvkk.page.html',
  styleUrls: ['./kvkk.page.scss'],
})
export class KvkkPage implements OnInit {
  @Input() type: string;
  @Input() videoLink;
  isKVKK: boolean;
  isTERMS: boolean;
  remark: any;
  remarkPrivacyConditions: any;
  remarkTermsAndConditions: any;
  remarkCancelAndRefundConditions: any;
  videoLinkProperty;
  isVideoLink: boolean;
  constructor(private modalController: ModalController,
    private domSanitazer: DomSanitizer,
    private storageService: StorageService) { }

  ngOnInit() {
    if (this.type == "kvkk") {
      this.storageService.getItem("anaMenu").then(res => {
        this.remark = res.MobileHotelDefinition.CompanyKvkkCondition
        this.remarkType(this.type)
        this.isKVKK = true
        this.isTERMS = false
        this.isVideoLink = false
      })
      this.isKVKK = true
      this.isTERMS = false
    } else if(this.type == "terms"){
      this.storageService.getItem("anaMenu").then(res => {
        this.remark = res.MobileHotelDefinition.CompanyTermsAndCondition
        this.remarkType(this.type)
        this.isKVKK = false
        this.isTERMS = true
        this.isVideoLink = false
      })
    }else if(this.type == "videoLink"){
      this.remark = this.videoLink.Name
      this.videoLinkProperty = this.domSanitazer.bypassSecurityTrustResourceUrl(this.videoLink.Link)
     this.isVideoLink = true
    }
  }

  remarkType(type) {
    switch (type) {
      case "kvkk":
      this.findRemarkForKVKK()
        break;

        case "terms":
      this.findRemarkForTERMS()
        break;

      default:
        break;
    }
  }

  findRemarkForKVKK() {
    this.storageService.getItem("LangID").then(res => {
      switch (res) {
        case EnumLanguageType.Turkish:
        this.remark = this.remark.KvkkText
          break;

          case EnumLanguageType.English:
        this.remark = this.remark.KvkkTextEN
          break;

        default:
          break;
      }
    })
  }

  findRemarkForTERMS() {
    this.storageService.getItem("LangID").then(res => {
      switch (res) {
        case EnumLanguageType.Turkish:
        this.remarkCancelAndRefundConditions = this.remark.CancelAndRefundConditions
        this.remarkPrivacyConditions = this.remark.PrivacyConditions
        this.remarkTermsAndConditions = this.remark.TermsAndConditions
          break;

          case EnumLanguageType.English:
        this.remarkCancelAndRefundConditions = this.remark.CancelAndRefundConditionsEN
        this.remarkPrivacyConditions = this.remark.PrivacyConditionsEN
        this.remarkTermsAndConditions = this.remark.TermsAndConditionsEN
          break;

        default:
          break;
      }
    })
  }

  dismiss() {
    this.modalController.dismiss({
      'dismissed': true
    });
  }
}
