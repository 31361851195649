import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { EnumLanguageType } from '../models/enums/enum-language-type.enum';
import { StorageService } from './storage.service';

@Injectable({
  providedIn: 'root'
})
export class TranslatingService {
  setLanguage;
  deviceLanguage: any;

  constructor(private translate: TranslateService,
    private storageService: StorageService) { }
  switchLanguage(language) {
    if (typeof (language) != "number") {
      language = parseInt(language)
    }
  console.log(language, "1. func")
   this.translate.use(this.languageSelector(language))
  }

  getDeviceLanguage():Promise<any>{
   return this.storageService.getItem("LangID").then(res=>{
      if(res==null || res==undefined){
        return this.deviceLanguage = this.languageSelectorFromStringToNumber(this.translate.getBrowserLang())
      }else{
        return res
      }
    }).catch(res=>{
      return this.deviceLanguage = this.languageSelectorFromStringToNumber(this.translate.getBrowserLang())
    })
  }

  languageSelector(languageID) {
    switch (languageID) {
      case EnumLanguageType.Turkish:
       return "tr"
        break;
      case EnumLanguageType.English:
        return "en"
        break;
        case EnumLanguageType.Deutsch:
        return "de"
        break;
        case EnumLanguageType.French:
        return "fr"
        break;
        case EnumLanguageType.Italian:
        return "it"
        break;
        case EnumLanguageType.русский:
        return "ru"
        break;
        case EnumLanguageType.Greek:
        return "el"
        break;

      default:
        return "en"
        break;
    }
  }

  languageSelectorFromStringToNumber(langString){
    switch (langString) {
      case "tr":
        return EnumLanguageType.Turkish;
        break;
        case "en":
        return EnumLanguageType.English
        break;
        case "de":
        return EnumLanguageType.Deutsch
        break;
        case "fr":
        return EnumLanguageType.French
        break;
        case "it":
        return EnumLanguageType.Italian
        break;
        case "ru":
        return EnumLanguageType.русский
        break;
        case "el":
        return EnumLanguageType.Greek
        break;
    
      default:
        return EnumLanguageType.English
        break;
    }
  }
  getSelectedLanguageTitle(key){
    switch (Number(key)) {
      case EnumLanguageType.Turkish:
        return "Türkçe"
        break;
      case EnumLanguageType.English:
        return "English"
        break;
        case EnumLanguageType.русский:
          return "русский"
          break;

      default:
        break;
    }
  }
}
