export enum ModalTpyes {
    weather = 1,
    basket = 2,
    payment = 3,
    threeD_HTML = 4,
    login = 5,
    sedna360Survey = 6,
    internetLink = 7,
    preCheckinLink = 8,
    kvkk = 9,
    termsAndConditions = 10,
    rating = 11,
    advertisement=12,
    videoLink = 13,
    doorLock = 14,
    networkPage = 15,
    pdf = 16,
    QRCode = 17
}
