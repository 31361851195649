export class GetActivityMenusAndFoodsDto {
    activityId: number;
    hotelId: number;
    constructor(activityId: number,hotelId: number){
        this.activityId = activityId;
        this.hotelId = hotelId;
    }

    FoodInfoView :number| null;
    UseFoodPrice :number| null;
    UseFoodSelect :number| null;
    UseFixMenu :number| null;
    RightFinishPrice :number| null;
    ImageNotView :number| null;
    RPax :number| null;
    RChild :number| null;
}
