export class paymentModel  {
    VirtualPosId:string
      CardNumber:string
      CardHolder:string
      CVV:string
      Amount:number
      Currency:string
      CreditCardType:string
      ExpireYear:string
      ExpireMonth:string
      Installment:string
      PaymentOkUrl:string
      PaymentFailUrl:string
      PayeeNote:string
      Email:string
      ECollectionPacketType:number
      ECollectionType:number
      AccessToken:string

}