export const Endpoints = {
    Precheckin:{
        getPrecheckinParameterOfCompany: 'v1/OnlineCheckIn/GetPreCheckInParameterOfCompany',
        login:'v1/OnlineCheckIn/Login',
        saveOnlineCheckInTempReservation:'v1/onlinecheckin/SaveOnlineCheckInTempReservation',
        identityValidationControl:'v1/OnlineCheckIn/IdentityValidationControl',
        saveCheckin:'v1/OnlineCheckIn/SaveCheckIn',
        checkReservationCount:'v1/OnlineCheckIn/CheckReservationCount'
    },
    Company:{
        companyKvkkCondition:'v1/company/CompanyKvkkCondition'
    },
    Reservation:{
        GetActivityMenusAndFoodsByPms: 'v1/SednaMobileB2C/GetActivityMenusAndFoodsByPms',
        ReservationStatusCheck: 'v1/Reservation/ReservationStatusCheck'
    },
    AssaAploy: {
        GenerateAssaApployKeyHotel: 'v1/SednaMobileB2C/GenerateAssaApployKey',
        GenerateAssaKeyHotel: 'v1/SednaMobileB2C/GenerateAssaKeyHotel',
        GetSession: 'v1/SednaMobileB2C/GetSession'
    },
    MobileOrder: {
        GetMenusByDepartment: 'v1/SednaMobileB2C/GetMenusByDepartment'
    },
    Profile: {
        ProfileSearch: 'v1/Profile/ProfileSearch',
        RegisterGuest: 'v1/SednaMobileB2C/RegisterGuest'
    },
    SignUp: {
        SendActivationCode: 'v1/SednaMobileB2C/SendActivationCode'
    }
   
};