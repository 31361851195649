import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { HttpService } from './http.service';
import { StorageService } from './storage.service';
import { Platform } from '@ionic/angular';
import { HTTP } from '@ionic-native/http/ngx';
import { paymentTokenModel } from '../models/paymentTokenModel';
import { paymentInstallmentModel } from '../models/paymentInstallmentModel';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class PaymentService {
  apikey: any;
  tokenModel: any
  paymentModel: paymentTokenModel
  companyID: any;
  getInstallmentInfo: { CompanyId: any; Amount: number; Bin: string; };

  paymentRequestModel: any = {
    apiAddress: environment.PaymentRequestApiAddress,
    // apiAddress: "http://testpayment.kodsedna.com/",
    scope: "s360"
  }

  getTokenModel: any = {
    apiAddress: environment.getTokenModelApiAddress
    // apiAddress: "https://testapi.sedna360.com/api/v1/SednaMobileB2C/GetTokenForPaymentApiAsync?apiKey="
  }

  constructor(private storageService: StorageService,
    private httpClient: HttpClient,
     private platform: Platform,
      private http: HTTP) {
    this.http.setHeader('*', 'Access-Control-Allow-Origin', '*');
    this.http.setHeader('*', 'Access-Control-Allow-Methods', 'POST, GET, OPTIONS, PUT');
    this.http.setHeader('*', 'Accept', 'application/json');
    // this.http.setHeader('*', 'content-type', 'application/x-www-form-urlencoded');
    // this.http.setDataSerializer('json');
  }

  getToken(): Promise<any> {
    console.log("asds")
    return this.storageService.getItem("anaMenu").then(res => {
      if (this.platform.is("mobileweb") || this.platform.is("desktop") || window.location.href.match("sedna360mobileb2c")) {
        this.apikey = res.MobileHotelDefinition.Sedna360ApiKey
        this.companyID = res.MobileHotelDefinition.CompaniesId

        // var hdr = new HttpHeaders({ 'content-type': 'application/x-www-form-urlencoded' });
        // this.tokenModel = "grant_type=" + 'password' + "&apiKey=" + this.apikey + "&scope=" + this.paymentRequestModel.scope;
        this.tokenModel = {
          "grand_type": "password",
          "apiKey": this.apikey,
          "scope": this.paymentRequestModel.scope
        }
        return this.httpClient.get(this.getTokenModel.apiAddress+this.apikey).toPromise()
      } else {
        return new Promise((resolve, reject) => {
      
          // var hdr1 = new HttpHeaders();
           this.http.setHeader('*', String("content-type"), String("application/json"));
          //this.http.setDataSerializer('urlencoded');
          // this.http.setHeader('*', 'Content-Type', 'application/x-www-form-urlencoded');
           this.http.setDataSerializer('json');
          // alert(this.http.getHeaders)
         this.apikey = res.MobileHotelDefinition.Sedna360ApiKey
           
          
           this.companyID = res.MobileHotelDefinition.CompaniesId
          // alert(JSON.stringify(this.http.getHeaders("*")))
          // this.tokenModel = "grant_type=" + 'password' + "&apiKey=" + this.apikey + "&scope=" + this.paymentRequestModel.scope;
          
          this.http.get(this.getTokenModel.apiAddress+this.apikey, {}, {}).then(res=>{
            //alert("oldu inş"+JSON.stringify(res))
            //alert(JSON.stringify(this.http.getHeaders("*")))
            //alert(JSON.stringify(this.getTokenModel.apiAddress+this.apikey))
            resolve(JSON.parse(res.data));
            }).catch(err => {
            alert("payment ana menu get ios catch :" + JSON.stringify(err));
          });

          // this.http.post(this.paymentRequestModel.apiAddress + "Token", JSON.parse(data), {headers:httpHeader }).then(res => {
          //   alert("burdaaaa "+JSON.stringify(res))
          //   resolve(JSON.parse(res.data));
            
          // })
          //   .catch(err => {
          //     reject("yoksa burasımı" + err);
          //     alert("burası mı" + err)
          //     console.log("token alırken hata ", err)
          //   });
        });
      }
    }).catch(err => {
      alert("payment ana menu catch :" + JSON.stringify(err));
      });
  }
  pay(paymentObject): Promise<any> {
    //alert("pay içindeyiz "+JSON.stringify(this.platform.platforms()))
    if (this.platform.is("mobileweb") || this.platform.is("desktop") || window.location.href.match("sedna360mobileb2c")) {
      var hdr = new HttpHeaders({ 'Authorization': 'Bearer ' + paymentObject.AccessToken });
      return this.paymentPost(this.paymentRequestModel.apiAddress + "api/Bank/Payment", paymentObject, { responseType: "text", headers: hdr }).then(res => {
        return res
      })
    }else{
      return new Promise((resolve, reject) => {
        this.http.setHeader('*', 'Authorization', 'Bearer ' + paymentObject.AccessToken);
        this.http.setDataSerializer('json');
       // alert(JSON.stringify(this.http.getHeaders("*")))
        this.http.post(this.paymentRequestModel.apiAddress + "api/Bank/Payment", paymentObject, { responseType: "text" }).then(res => {
          //alert("pay cevap geldi: "+JSON.stringify(res))
          resolve(res.data);
        })
          .catch(err => {
            reject(err);
            //hatalı döndükten sonra, HTTP DEKİ AUTHORİZATİON HEADERINI
            //SİLDİK ÇÜNKÜ diğer sayfalardaki isteklerde HATAYA DÜŞÜRÜYODU Y.E
            this.http.setHeader('*', 'Authorization', '');
          });
      });
    }
    
  }
  getInstallment(installmentModel: paymentInstallmentModel): Promise<any> {
    console.log("asdasd")
    installmentModel.CompanyId = this.companyID
    //alert("installment içindeyiz "+JSON.stringify(this.platform.platforms()))
    if (this.platform.is("mobileweb") || this.platform.is("desktop") || window.location.href.match("sedna360mobileb2c")) {
     // alert("yanlışa girdi")
       var hdr = new HttpHeaders({ 'Authorization': 'Bearer ' + installmentModel.AccessToken });
    return this.paymentPost(this.paymentRequestModel.apiAddress + "api/Bank/GetCardBankAndInstallmentInfo", installmentModel, { headers: hdr }).then(res => {
      return res
    })
    }else{
      return new Promise((resolve, reject) => {
        this.http.setHeader('*', 'Authorization', 'Bearer ' + installmentModel.AccessToken);
        this.http.setDataSerializer('json');
        //alert("installment model: "+JSON.stringify(installmentModel))
        //alert(JSON.stringify(this.http.getHeaders("*")))
        this.http.post(this.paymentRequestModel.apiAddress + "api/Bank/GetCardBankAndInstallmentInfo", installmentModel, {}).then(res => {
          resolve(JSON.parse(res.data));
        })
          .catch(err => {
            reject(err);
            //hatalı döndükten sonra, HTTP DEKİ AUTHORİZATİON HEADERINI
            //SİLDİK ÇÜNKÜ diğer sayfalardaki isteklerde HATAYA DÜŞÜRÜYODU Y.E
            this.http.setHeader('*', 'Authorization', '');
          });
      });
    }
   
  }
  paymentPost(url: string, body: any, myHeader: any): Promise<any> {

    if (this.platform.is("mobileweb") || this.platform.is("desktop") || window.location.href.match("sedna360mobileb2c")) {
      return this.httpClient.post<Promise<any>>(url, body, myHeader).toPromise();
    }
    else {
      return new Promise((resolve, reject) => {
        this.http.setDataSerializer('json');
        this.http.post(url, body, {}).then(res => {
          resolve(JSON.parse(res.data));
        })
          .catch(err => {
            reject(err);
            //alert(err)
            console.log("buradayım", err)
          });
      });
    }
  }
}
