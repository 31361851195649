export enum MenuTypes {
    
    menuNotExist = -1,
    menuWithImages = 0,
    noteTitles = 1,
    gallery = 3,
    internetLink = 4,
    html = 5,
    task = 8,
    activity = 9,
    wakeUpService = 11,
    preCheckIn = 12,
    video = 13,
    guestInfo = 14,
    guestFolioInfo = 15,
    reservation= 16,
    panel = 17,
    menuLeftImage = 18,
    periodicMenu = 19,
    sideMenu = 20,
    doubledMenu = 21,
    detail = 22,
    detailMenu = 23,
    sedna360Survey = 27, //precheckin ve anket ortak property
    product = 29,
    productDetail = 30,
    productList = 31,
    departmanDoubleMenu = 32,
    preCheckinLink = 33,
    singleActivity = 50,
    sss = 34,
    PDF = 35,
    transfer = 36,
    tourticket = 37,
    detailHtml = 24
}
