import { Injectable } from '@angular/core';
import { LoadingController } from '@ionic/angular';

@Injectable({
  providedIn: 'root'
})
export class LoadingService {
  loading:any;
  constructor(private loadingController: LoadingController) { }
   async show(){
    this.loading =  await this.loadingController.create({
      cssClass: 'my-custom-class',
      message: 'Please wait...',
    })
   return this.loading?.present()
  }

   async hide(){
    this.loading?.dismiss();
  }
}
