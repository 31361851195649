export enum EnumLanguageType {
    Turkish = 1,
    English = 184,
    русский = 185,
    Deutsch = 186,
    Abkhaz = 187,
    Afar = 188,
    Afrikaans = 189,
    Akan = 190,
    Albanian = 190,
    Amharic = 192,
    Arabic = 193,
    Aragonese = 194,
    Armenian = 195,
    Assamese = 196,
    Avaric = 197,
    Avestan = 198,
    Aymara = 199,
    Azerbaijani = 200,
    Bambara = 201,
    Bashkir = 202,
    Basque = 203,
    Belarusian = 204,
    Bihari = 206,
    Bislama = 207,
    Bosnian = 208,
    Breton = 209,
    Български = 210,
    Burmese = 211,
    Chamorro = 213,
    Chechen = 214,
    Chinese = 216,
    Chuvash = 217,
    Cornish = 218,
    Corsican = 219,
    Cree = 220,
    Croatian = 221,
    Czech = 222,
    Danish = 223,
    Dutch = 225,
    Dzongkha = 226,
    Esperanto = 227,
    Estonian = 228,
    Ewe = 229,
    Faroese = 230,
    Fijian = 231,
    Finnish = 232,
    French = 233,
    Galician = 235,
    Georgian = 236,
    Greek = 237,
    Guaraní = 238,
    Gujarati = 239,
    Hausa = 241,
    Hebrew = 242,
    Herero = 243,
    Hindi = 244,
    HiriMotu = 245,
    Hungarian = 246,
    Interlingua = 247,
    Indonesian = 248,
    Interlingue = 249,
    Irish = 250,
    Igbo = 251,
    Inupiaq = 252,
    Ido = 253,
    Icelandic = 254,
    Italian = 255,
    Inuktitut = 256,
    Japanese = 257,
    Javanese = 258,
    Kannada = 260,
    Kanuri = 261,
    Kashmiri = 262,
    Kazakh = 263,
    Khmer = 264,
    Kinyarwanda = 266,
    Kyrgyz = 267,
    Komi = 268,
    Kongo = 269,
    Korean = 270,
    Kurdish = 271,
    Latin = 273,
    Ganda = 275,
    Lingala = 277,
    Lao = 278,
    Lithuanian = 279,
    Latvian = 281,
    Manx = 282,
    Macedonian = 283,
    Malagasy = 284,
    Malay = 285,
    Malayalam = 286,
    Maltese = 287,
    Māori = 288,
    Marathi = 289,
    Marshallese = 290,
    Mongolian = 291,
    Nauru = 292,
    NorthernNdebele = 294,
    Nepali = 295,
    Ndonga = 296,
    NorwegianBokmål = 297,
    NorwegianNynorsk = 298,
    Norwegian = 299,
    Nuosu = 300,
    SouthernNdebele = 301,
    Occitan = 302,
    Oromo = 305,
    Oriya = 306,
    Pāli = 309,
    Persian = 310,
    Polish = 311,
    Portuguese = 313,
    Quechua = 314,
    Română = 315,
    Kirundi = 316,
    Romanian = 317,
    Sanskrit = 318,
    Sardinian = 319,
    Sindhi = 320,
    NorthernSami = 321,
    Samoan = 322,
    Sango = 323,
    Serbian = 324,
    Shona = 326,
    Slovak = 328,
    Slovene = 329,
    Somali = 330,
    SouthernSotho = 331,
    Sundanese = 333,
    Swahili = 334,
    Swati = 335,
    Swedish = 336,
    Tamil = 337,
    Telugu = 338,
    Tajik = 339,
    Thai = 340,
    Tigrinya = 341,
    Turkmen = 343,
    Tagalog = 344,
    Tswana = 345,
    Tongav = 346,
    Tsonga = 347,
    Tatar = 348,
    Twi = 349,
    Tahitian = 350,
    Uyghur = 351,
    Ukrainian = 352,
    Urdu = 353,
    Uzbek = 354,
    Venda = 355,
    Vietnamese = 356,
    Volapük = 357,
    Walloon = 358,
    Welsh = 359,
    Wolof = 360,
    WesternFrisian = 361,
    Xhosa = 362,
    Yiddish = 363,
    Yoruba = 364,
    Zulu = 366
}
