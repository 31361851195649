import { Injectable } from '@angular/core';
import { AlertController } from '@ionic/angular';
import { RouteLinkParametersService } from './route-link-parameters.service';

@Injectable({
  providedIn: 'root'
})
export class AlertService {
  constructor(
    public alertController: AlertController,
    private routelink: RouteLinkParametersService) { }

  async alertRedirect(header: string, message: string, url: string, okButtonText: string) {
    const alert = await this.alertController.create({
      cssClass: 'my-custom-class',
      header: header,
      message: message,
      backdropDismiss: false,
      buttons: [
        // {
        //   text: 'Cancel',
        //   role: 'cancel',
        //   cssClass: 'secondary',
        //   handler: (blah) => {
        //     console.log('Confirm Cancel: blah');
        //   }
        // },
        {
          text: okButtonText,
          handler: () => {
            // this.router.navigate([url])
            this.routelink.getRouteLinkParameters("/dashboard", {})
          }
        }
      ],
    });
    await alert.present();
  }
}
