import { Component, Input, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { ratingTypes } from 'src/app/models/enums/ratingTypes.enum';
import { HttpService } from 'src/app/services/http.service';
import { StorageService } from 'src/app/services/storage.service';

@Component({
  selector: 'app-rating-modal',
  templateUrl: './rating-modal.page.html',
  styleUrls: ['./rating-modal.page.scss'],
})
export class RatingModalPage implements OnInit {
  @Input() data;
  icons: Array<any> = [
    { name: "star-outline" },
    { name: "star-outline" },
    { name: "star-outline" },
    { name: "star-outline" },
    { name: "star-outline" },
  ]
  // Oldicons:Array<any> = [
  //   {name: "heart"},
  //   {name: "heart"},
  //   {name: "heart"},
  //   {name: "heart"},
  //   {name: "heart"},
  // ]
  rateNumber: any;
  reason: string;
  showReasonInput: boolean = false;
  oldScore: any;
  oldRemark: any;
  guestID: any;
  Score: any = 0;
  Remark: any;
  type: any;
  ratingModel: { Type: any; TableId: any; GuestId: any; Score: any; Remark: any; };
  lastRateModel: { TableId: any; GuestId: any; Type: any; };
  RatingRemarkCount: any;
  constructor(private modalController: ModalController,
    private http: HttpService,
    private storageService: StorageService) { }

  ngOnInit() {
    this.storageService.getItem("guestId").then(res => {
      if (res != undefined || res != null) {
        this.guestID = res
        this.storageService.getItem("RatingRemarkCount").then(RatingRemarkCount=>{
          this.RatingRemarkCount = RatingRemarkCount
          this.getLastRate()
        })
        
      } else {

      }
    }).catch(err => {

    })

    // if(this.data.Rating){
    //   this.oldScore = this.data.Rating.Score
    //   this.oldRemark = this.data.Rating.Remark
    //   this.fillOldScore()
    // }
    // console.log("rating data",this.data)
  }
  getType() {
    if (this.data.ratingType) {
      this.type = ratingTypes.Department
      return this.type
    } else {
      this.type = ratingTypes.General
      return this.type
    }
  }
  fillLastRateModel(id){
    this.lastRateModel = {
      "TableId": id,
      "GuestId": this.guestID,
      "Type": this.getType()
    }
  }
  getLastRate(): Promise<any> {
    if(this.data.RecId){
      this.fillLastRateModel(this.data.RecId)
    }else{
     this.fillLastRateModel(this.data.ActivityId)
    }
    
    return this.http.post("v1/SednaMobileB2C/GetLastRatingOfMenu", this.lastRateModel).then(res => {
      if (res != null || res != undefined) {
        // this.oldScore = res.Score
        // this.oldRemark = res.Remark
        this.Remark = res.Remark
        this.Score = res.Score
        this.fillScore(this.Score-1)
      } else {
        this.Remark = null
        this.Score = 0
        this.icons = [
          { name: "star-outline" },
          { name: "star-outline" },
          { name: "star-outline" },
          { name: "star-outline" },
          { name: "star-outline" },
        ]
      }
    })
  }
  fillRatingModel(id){
    this.ratingModel = {
      "Type": this.getType(),
      "TableId": id,
      "GuestId": this.guestID,
      "Score": this.Score,
      "Remark": this.Remark
    }
  }
  postRate() {
    if (this.Score == 0) {

    } else {
      if (this.Score > 2) {
        this.Remark = ""
      }
      if (this.data.RecId) {
        this.fillRatingModel(this.data.RecId)
      } else {
        this.fillRatingModel(this.data.ActivityId)
      }

      this.http.post("v1/SednaMobileB2C/AddOrUpdateRating", this.ratingModel).then(res => {
        this.getLastRate()
        this.dismiss()
      })
    }
  }

  fillScore(index) {
    for (var i = 0; i < 5; i++) {
      if (index >= i) {
        this.icons[i].name = "star"
      } else {
        this.icons[i].name = "star-outline"
      }
    }
    this.Score = index + 1
    if (this.Score < this.RatingRemarkCount) {
      this.showReasonInput = true
    } else {
      this.showReasonInput = false
    }
    console.log("you have voted: ", this.Score)
  }

  // fillOldScore(){
  //   for(var i = 0; i<5; i++){

  //     if(this.oldScore>i){
  //       this.Oldicons[i].name = "heart"
  //     }else{
  //       this.Oldicons[i].name = "star-outline"
  //     }
  //   }
  // }
  dismiss() {
    this.modalController.dismiss({
      'dismissed': true
    });
  }
}
