import { Injectable } from '@angular/core';
import { ToastController } from '@ionic/angular';

@Injectable({
  providedIn: 'root'
})
export class ToasterService {
  toaster;
  constructor(private toastController: ToastController) { }

  async toast(msg: string, type: string, duration:number) {

    switch (type) {
      case "success":
        this.toaster = await this.toastController.create({
          message: msg,
          cssClass: "successToast",  //değiştirmek isterseniz variables.scss içinde en dışta yani
          position: 'top',
          duration: duration
        });
        this.toaster.present();
        break;
      case "fail":
        this.toaster = await this.toastController.create({
          message: msg,
          cssClass: "failToast",    //değiştirmek isterseniz variables.scss içinde en dışta yani
          position: 'top',
          duration: duration
        });
        this.toaster.present();
        break;
      case "warning":
        this.toaster = await this.toastController.create({
          message: msg,
          cssClass: "warningToast",    //değiştirmek isterseniz variables.scss içinde en dışta yani
          position: 'top',
          duration: duration
        });
        this.toaster.present();
        break;

      default:
        break;
    }
  }
}
