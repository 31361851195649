import { registerPlugin } from '@capacitor/core';

interface IDoorLockPlugin {
    doorLock(options: { 
         doorLockKey: string;
         url: string;
         Token: string;
         ResId: string;
         CompanyRecId: number;
         EndpointId: number;
         CompanyId:string;
         GuestId: number;
         DeviceId: string;
         CardId: string;
         RoomNumber: string;
     }): Promise<{ 
        doorLockKey: string; 
        url: string;
        Token: string;
        ResId: string;
        CompanyRecId: number;
        EndpointId: number;
        CompanyId:string;
        GuestId: number;
        DeviceId: string;
        CardId: string;
        RoomNumber: string;
    }>;
}
const doorPlugin = registerPlugin<IDoorLockPlugin>('doorLock');
export default doorPlugin;