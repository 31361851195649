import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ReservationPage } from '../pages/reservation/reservation.page';
import { AlertService } from './alert.service';
import { PlatformDetectionService } from './platform-detection.service';
import { StorageService } from './storage.service';
import { TranslatingService } from './translate.service';

@Injectable({
  providedIn: 'root'
})
export class SystemService {
  downloadTimer
  dialog
  externalTime
  deviceLanguage

  constructor(private alertService: AlertService,
    private translateService:TranslateService,
    private translatingSerivce: TranslatingService,
    private translate: TranslateService, private storageService: StorageService, private platformDetection: PlatformDetectionService) { }

  findTheDayNameOfTheDate(date: Date) {
    this.storageService.getItem("LangID").then(res => {
      this.translatingSerivce.switchLanguage(res)
    })
    // this.deviceLanguage = this.translatingSerivce.languageSelectorFromStringToNumber(this.translate.getBrowserLang())
    // console.log(this.deviceLanguage)
    // this.storageService.setItem("LangID", this.deviceLanguage)
    // this.translatingSerivce.switchLanguage(this.deviceLanguage)
    var result
    switch (date.getDay()) {
      case 0:
        this.translate.get("Sunday").subscribe(res => {
          result = res;
        })
        break;
      case 1:
        this.translate.get("Monday").subscribe(res => {
          result = res;
        })
        break;
      case 2:
        this.translate.get("Tuesday").subscribe(res => {
          result = res;
        })
        break;
      case 3:
        this.translate.get("Wednesday").subscribe(res => {
          result = res;
        })
        break;
      case 4:
        this.translate.get("Thursday").subscribe(res => {
          result = res;
        })
        break;
      case 5:
        this.translate.get("Friday").subscribe(res => {
          result = res;
        })
        break;
      case 6:
        this.translate.get("Saturday").subscribe(res => {
          result = res;
        })
        break;

      default:
        break;
    }
    return result
  }
  onlineDaysCheck(data, date: Date) {
    switch (date.getDay()) {
      case 0:
        if (data.Sunday == 1) {
          return true;
        } else {
          return false;
        }
        break;
      case 1:
        if (data.Monday == 1) {
          return true;
        } else {
          return false;
        }

        break;
      case 2:
        if (data.Tuesday == 1) {
          return true;
        } else {
          return false;
        }
        break;
      case 3:
        if (data.Wednesday == 1) {
          return true;
        } else {
          return false;
        }
        break;
      case 4:
        if (data.Thursday == 1) {
          return true;
        } else {
          return false;
        }
        break;
      case 5:
        if (data.Friday == 1) {
          return true;
        } else {
          return false;
        }
        break;
      case 6:
        if (data.Saturday == 1) {
          return true;
        } else {
          return false;
        }
        break;

      default:
        break;
    }
    return true;
  }

  dateConvertString(date: string): string{
    var newDate = date.substring(0, 10);
    var splittedDate = newDate.split('-');
    return splittedDate[0] + '-' + splittedDate[1] + '-' + splittedDate[2];
  }

  dateConverter(date: string): string {
    var convertedDate = new Date(date);
    return convertedDate.getFullYear() + '-' + (convertedDate.getMonth() + 1) + '-' + convertedDate.getDate();
  }

  dateConverterTraceList(date: string): string {
    //alert(JSON.stringify(date));
    
    if(this.platformDetection.isIOS()){
      return date;
    }else{
      var convertedDate = new Date(date);
      return convertedDate.getFullYear() + '-' + (convertedDate.getMonth() + 1) + '-' + convertedDate.getDate();
    }
    //alert(JSON.stringify(convertedDate));
    
  }
  //periodik menüdeki etkinliklerin saati geçmişse listede gösterme
  periodicMenuCompareTime(data) {
    var programTime = new Date(data)
    var today = new Date();

    var TodayHour = today.getHours()
    var TodayMinute = today.getMinutes()

    var dataHour = programTime.getHours()
    var dataMinute = programTime.getMinutes()
    
    if (TodayHour > dataHour) {
      return true
    } else if (TodayHour == dataHour) {
      if (TodayMinute > dataMinute) {
        return true
      } else {
        return false
      }
    }
    else {
      return false
    }
  }

  dateConverterFromDaysToYear(date: string): string {
    var convertedDate = new Date(date);
    return convertedDate.getDate() + '-' + (convertedDate.getMonth() + 1) + '-' + convertedDate.getFullYear();
  }

  getLangID(): Promise<any> {
    return this.storageService.getItem("LangID").then(res => {
      return res
    }).catch(res => {
      alert("qr langid storage yok")
    })
  }

  timer(time: number) {
    this.clearTimer()
    var as = this;
    this.downloadTimer = setInterval(function () {
      if (time <= 0) {
        clearInterval(as.downloadTimer);

        as.translateService.get("ok").toPromise().then(okButtonText => {
          as.translateService.get("warning").toPromise().then(header => {
            as.translateService.get("reservationTimeError").toPromise().then(message => {
              as.alertService.alertRedirect(header, message, "/dashboard", okButtonText);
              ReservationPage.demo.close()
            })
          })
        })

      }
      time -= 1;
      as.externalTime = time;
    }, 1000);
  }
  clearTimer() {
    clearInterval(this.downloadTimer);
  }

  returnTimer() {
    return this.externalTime;
  }
}
