import { Component, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { ModalTpyes } from 'src/app/models/enums/modal-types.enum';
import { paymentInstallmentModel } from 'src/app/models/paymentInstallmentModel';
import { paymentModel } from 'src/app/models/paymentModel';
import { ModalControllerService } from 'src/app/services/modalController.service';
import { PaymentService } from 'src/app/services/payment.service';
import { SharedService } from 'src/app/services/shared.service';
import { StorageService } from 'src/app/services/storage.service';
import { Location } from '@angular/common';
import { HTTP } from '@ionic-native/http/ngx';

@Component({
  selector: 'app-payment',
  templateUrl: './payment.page.html',
  styleUrls: ['./payment.page.scss'],
})
export class PaymentPage implements OnInit {
  // mask = [/\d/, /\d/, ' ', /\d/, /\d/, ' ', /\d/, /\d/, /\d/, /\d/];
  mask = [/[1-9]/, /\d/, /\d/, /\d/, ' ', /\d/, /\d/, /\d/, /\d/, ' ', /\d/, /\d/, /\d/, /\d/, ' ', /\d/, /\d/, /\d/, /\d/,]
  CVVmask = [/[1-9]/, /\d/, /\d/, /\d/]
  cardImageUrl = "../assets/cardTypeImages/default.png"
  yearsArray: Array<any> = [];
  cardOwner;
  cardNumber
  monthsArray = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12]
  basketArray: any;
  currency: any;
  totalPrice: number;
  year: any
  month: any
  cvv;
  paymentObject: paymentModel
  stopper: number;
  installmentObj: any;
  paymentModel: paymentInstallmentModel;
  bakiyeArray: any;
  showPaymentButton: boolean = false;
  cardNumberBin: any;
  constructor(private storageService: StorageService,
    private modalControllerService: ModalControllerService,
    public modalController: ModalController,
    private paymentService: PaymentService,
    private shared: SharedService,
    private http: HTTP,
    private location: Location) {
    this.shared.paymentRedirecTriggerObservable.subscribe(res => {
      if (res == "closeCreditCardModal") {
        console.log(" payment constractor girdi")
        this.modalController.dismiss({
          'dismissed': true
        }).then(res => {
          this.shared.updateBalanceRedirecTrigger("redirectToDashboard")
          this.shared.updateBalanceRedirecTrigger(null)

        }).catch(err => {
          alert('alert: ' + JSON.stringify(err))

        });

      } else {

      }
    })
  }
  presentModal(modalType) {
    this.modalControllerService.presentModal(modalType, undefined);
  }
  dismiss() {
    this.modalController.dismiss({
      'dismissed': true
    });
  }
  ngOnInit() {
    this.storageService.getItem("totalBakiye").then(res => {
      // this.basketArray = res;
      // this.currency = res[0].par3
      this.totalPrice = res
    })
    this.storageService.getItem("CurrencyCode").then(res => {
      this.currency = res
    })

    var today = new Date();  //bugünün tarihi
    var todaysYear = today.getFullYear()  //bulunduğumuz yıl
    for (var i = 0; i < 10; i++) {   //bulunduğumuz yıla 10 ekleyerek array oluşturuyoruz select için
      this.yearsArray.push(todaysYear + i);
    }
  }
  onChange() {
    this.GetCreditCardType(this.cardNumber)
  }
  GetCreditCardType(creditCardNumber) {

    // start without knowing the credit card type
    var result = "unknown";
    this.mask = [/[1-9]/, /\d/, /\d/, /\d/, ' ', /\d/, /\d/, /\d/, /\d/, ' ', /\d/, /\d/, /\d/, /\d/, ' ', /\d/, /\d/, /\d/, /\d/]
    // first check for MasterCard
    if (/^5[1-5]/.test(creditCardNumber)) {
      this.checkLength(creditCardNumber)
      result = "mastercard";
      this.cardImageUrl = "../assets/cardTypeImages/mastercard.png"
      this.mask = [/[1-9]/, /\d/, /\d/, /\d/, ' ', /\d/, /\d/, /\d/, /\d/, ' ', /\d/, /\d/, /\d/, /\d/, ' ', /\d/, /\d/, /\d/, /\d/]
    }
    // then check for Visa
    else if (/^4/.test(creditCardNumber)) {
      this.checkLength(creditCardNumber)
      result = "visa";
      this.cardImageUrl = "../assets/cardTypeImages/visa.png"
      this.mask = [/[1-9]/, /\d/, /\d/, /\d/, ' ', /\d/, /\d/, /\d/, /\d/, ' ', /\d/, /\d/, /\d/, /\d/, ' ', /\d/, /\d/, /\d/, /\d/]
    }
    // then check for AmEx
    else if (/^3[47]/.test(creditCardNumber)) {
      this.checkLength(creditCardNumber)
      result = "amex";
      this.cardImageUrl = "../assets/cardTypeImages/amex.png"
      this.mask = [/[1-9]/, /\d/, /\d/, /\d/, ' ', /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, ' ', /\d/, /\d/, /\d/, /\d/, /\d/]
    }
    // then check for Diners
    else if (/3(?:0[0-5]|[68][0-9])[0-9]{11}/.test(creditCardNumber)) {
      this.checkLength(creditCardNumber)
      result = "diners"
      this.cardImageUrl = "../assets/cardTypeImages/diners.png"
      this.mask = [/[1-9]/, /\d/, /\d/, /\d/, ' ', /\d/, /\d/, /\d/, /\d/, ' ', /\d/, /\d/, /\d/, /\d/, ' ', /\d/, /\d/]
    }
    // then check for Discover
    else if (/6(?:011|5[0-9]{2})[0-9]{12}/.test(creditCardNumber)) {
      this.checkLength(creditCardNumber)
      result = "discover";
      this.cardImageUrl = "../assets/cardTypeImages/discover.png"
      this.mask = [/[1-9]/, /\d/, /\d/, /\d/, ' ', /\d/, /\d/, /\d/, /\d/, ' ', /\d/, /\d/, /\d/, /\d/, ' ', /\d/, /\d/, /\d/, /\d/]
    }
    return result;
  }
  checkLength(cardNumber) {
    console.log(cardNumber.match("_"))
    if (this.year != undefined && this.month != undefined && cardNumber.match("_") == null && this.cardOwner != undefined && this.cvv != undefined && this.cvv != "" && this.cardOwner != "") {
      this.showPaymentButton = true
      this.cardNumberBin = this.cardNumber.split(" ").join("").substring(0, 6)

    } else {
      this.showPaymentButton = false
    }
  }
  pay() {
    var convertedYear = this.year.substring(2, 4)
    this.paymentService.getToken().then(token => {
      // alert("token aldıktan sonra "+JSON.stringify(token))
      if (token.access_token != "undefined") {
        console.log(token)
        // alert(JSON.stringify("token aldık "+token.access_token))
        this.paymentModel = new paymentInstallmentModel();
        this.paymentModel.AccessToken = token.access_token;
        this.paymentModel.Bin = this.cardNumberBin;
        // this.paymentModel.Amount = 0.2;
        // this.paymentModel.CompanyId = 
        if (this.cvv.match("_")) {
          this.cvv = this.cvv.replace('_', '');
        }
        this.cardNumber = this.cardNumber.replace(/\s/g, "");
        this.paymentService.getInstallment(this.paymentModel).then(res => {
          // alert("taksit aldık: "+JSON.stringify(res))
          this.installmentObj = res.Response
          console.log("installment obj ", this.installmentObj)
          this.paymentObject = new paymentModel();
          this.paymentObject.VirtualPosId = this.installmentObj.VirtualPos.Id;
          this.paymentObject.CardNumber = this.cardNumber;
          this.paymentObject.CardHolder = this.cardOwner;
          this.paymentObject.CVV = this.cvv;
          this.paymentObject.Amount = this.totalPrice;
          this.paymentObject.Currency = this.currency;
          this.paymentObject.CreditCardType = this.installmentObj.Bin.organization;
          this.paymentObject.ExpireYear = convertedYear;
          this.paymentObject.ExpireMonth = this.month;
          this.paymentObject.Installment = "1";
          this.paymentObject.PaymentOkUrl = "https://mobile.sedna360.com/success-payment"; 
          this.paymentObject.PaymentFailUrl = "https://mobile.sedna360.com/fail-payment"; 
          this.paymentObject.ECollectionPacketType = 1,
            this.paymentObject.ECollectionType = 2;
          this.paymentObject.AccessToken = token.access_token;
          console.log("bakacaz", this.paymentObject)
          this.paymentService.pay(this.paymentObject).then(res => {
            // alert("pay aldık: "+JSON.stringify(res))
            console.log("result = ", res);
            this.shared.update3DHTML(res);
            this.modalControllerService.presentModal(ModalTpyes.threeD_HTML, undefined)
            //payment kart işlemi başarılı döndükten sonra, HTTP DEKİ AUTHORİZATİON HEADERINI
            //SİLDİK ÇÜNKÜ diğer sayfalardaki isteklerde HATAYA DÜŞÜRÜYODU Y.E
            this.http.setHeader('*', 'Authorization', '');
          });
        })
      }
      console.log("get token ", token.access_token)
    }).catch(err => {
      alert("token alırken hata: " + JSON.stringify(err))
    });
  }

  paytest() {
    this.paymentService.getToken().then(token => {
      if (token.access_token != "undefined") {
        console.log("gelen veri test fonsksiyonuna", token)
        this.paymentModel = new paymentInstallmentModel();
        this.paymentModel.AccessToken = token.access_token;
        this.paymentModel.Bin = this.cardNumberBin;
        this.paymentService.getInstallment(this.paymentModel).then(res => {
          this.installmentObj = res.Response
          console.log("installment obj ", this.installmentObj)
          this.paymentObject = new paymentModel();
          this.paymentObject.VirtualPosId = this.installmentObj.VirtualPos.Id;
          this.paymentObject.CardNumber = "4543607259926960";
          this.paymentObject.CardHolder = "YUNUS EMRE UVEYIK";
          this.paymentObject.CVV = "428";
          this.paymentObject.Amount = 0.2;
          this.paymentObject.Currency = "USD";
          this.paymentObject.CreditCardType = "VISA";
          this.paymentObject.ExpireYear = "25";
          this.paymentObject.ExpireMonth = "12";
          this.paymentObject.Installment = "1";
          this.paymentObject.PaymentOkUrl = "https://www.google.com";
          this.paymentObject.PaymentFailUrl = "www.hotmail.com";
          this.paymentObject.ECollectionPacketType = 1,
            this.paymentObject.ECollectionType = 2;
          this.paymentObject.AccessToken = token.access_token;
          console.log(this.paymentObject)
          this.paymentService.pay(this.paymentObject).then(res => {
            console.log("result = ", res);
            this.shared.update3DHTML(res);
            this.modalControllerService.presentModal(ModalTpyes.threeD_HTML, undefined)
          });
        })
      }
      console.log("get token ", token.access_token)
    }).catch(err => {
      alert("token alırken hata: " + JSON.stringify(err))
    });
  }

  calculateTotalPrice() {
    var totalPrice = 0;
    for (var i = 0; i < this.bakiyeArray.length; i++) {
      totalPrice = this.bakiyeArray[i].quantity * this.bakiyeArray[i].Par4 + totalPrice;
    }
    return totalPrice;
  }
}
