import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { from, Observable } from 'rxjs';
import { Endpoints } from 'src/environments/endpoints';
import { environment } from 'src/environments/environment';
import { HttpService } from './http.service';
import { StorageService } from './storage.service';

@Injectable({
  providedIn: 'root'
})
export class AssaaployService {

  accessToken;
  apiAdress: string = environment.apiAddress + "/api/";
  constructor(private httpClient: HttpClient, private storageService: StorageService) { }

  //#region GenerateAssaApployKeyHotel
  async getAssaaployKey(companyRecIdAssa: number, reservationId: number, companyId: string, guestId: number, deviceId: string): Promise<any> {
    var fullUrl: string = this.apiAdress + Endpoints.AssaAploy.GenerateAssaApployKeyHotel;
    const assaAployObject = { CompanyRecId: companyRecIdAssa, ResId: reservationId, CompanyId: companyId, GuestId: guestId, DeviceId: deviceId }

    return this.assaaployPost(fullUrl, assaAployObject).then(res => {
      return res;
    });
  }
  getAssaaployKey2(companyRecIdAssa: number, reservationId: number, companyId: string, guestId: number,roomNumber: string, deviceId: string, apployKey: string, enpointId: string): Observable<any> {
    var fullUrl: string = this.apiAdress + Endpoints.AssaAploy.GenerateAssaApployKeyHotel;
    const assaAployObject = { CompanyRecId: companyRecIdAssa, ResId: reservationId, CompanyId: companyId, GuestId: guestId, DeviceId: deviceId, ApployKey: apployKey, EnpointId: enpointId,RoomNumber: roomNumber }
    
    return this.httpClient.post(fullUrl, assaAployObject);
  }
  async generateAssaKeyHotel(companyRecIdAssa: number, reservationId: number, companyId: string, endpointId: string, ApployKey: String): Promise<any> {

    var fullUrl: string = this.apiAdress + Endpoints.AssaAploy.GenerateAssaKeyHotel;
    const assaAployObject = { CompanyRecId: companyRecIdAssa, ResId: reservationId, companyId, endpointId, ApployKey }

    return this.assaaployPost(fullUrl, assaAployObject).then(res => {
      return res;
    });

  }

  assaaployPost(url: string, body: any): Promise<any> {
    return this.httpClient.post<Promise<any>>(url, body).toPromise();
  }
  //#endregion

  //#region getSession
  async getAssaaploySession(_Room: string, _CheckoutDate: Date, _EndpointId: string, _description: string, _CompanyId: string): Promise<any> {
    debugger;
    var fullUrl: string = this.apiAdress + Endpoints.AssaAploy.GetSession;
    const assaAployObject = { Room: _Room, CheckoutDate: _CheckoutDate, EndpointId: _EndpointId, description: _description, CompanyId: _CompanyId }

    return this.assaaployPost(fullUrl, assaAployObject).then(res => {
      return res;
    });
  }

  assaaploySessionPost(url: string, body: any): Promise<any> {
    return this.httpClient.post<Promise<any>>(url, body).toPromise();
  }



  //#endregion
}
