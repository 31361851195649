import { Component, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { StorageService } from 'src/app/services/storage.service';
import { basketModel } from 'src/app/models/basketModel';
import { ModalControllerService } from 'src/app/services/modalController.service';
import { HttpService } from 'src/app/services/http.service';
import { ToasterService } from 'src/app/services/toaster.service';
import { TranslateService } from '@ngx-translate/core';
import { SharedService } from 'src/app/services/shared.service';
import { LoadingService } from 'src/app/services/loading.service';
import { TranslatingService } from 'src/app/services/translate.service';

@Component({
  selector: 'app-basket',
  templateUrl: './basket.page.html',
  styleUrls: ['./basket.page.scss'],
})
export class BasketPage implements OnInit {
  basketArray: Array<basketModel> = []
  totalPrice: number;
  currency: any;
  selectedTable;
  orderNote;
  reservationID: any;
  guestID: any;
  postOrderObject: any = {
    "RecId": 0,
    "Body": {
      "Products": []
    }
  }

  hotelID: any;
  selectedDepartment: any;
  tableList: any;
  backButtonBoolean: boolean;
  GuestFullName: string;
  LangID: any;
  veboniOrder: boolean = false;
  constructor(public modalController: ModalController,
    private storageService: StorageService,
    private modalControllerService: ModalControllerService,
    private http: HttpService,
    private toastr: ToasterService,
    private translate: TranslateService,
    private shared: SharedService,
    private loading: LoadingService,
    private TranslatingService: TranslatingService) { }

  ngOnInit() {
    this.storageService.getItem("basketItems").then(res => {
      if (res != null && res != undefined && res != "") {
        this.basketArray = res;
        this.currency = res[0].par3
        this.calculateTotalPrice()
      } else {
        this.basketArray = []
      }
      console.table(this.basketArray);
    }).catch(err => {
      this.basketArray = []
    })

    this.storageService.getItem("anaMenu").then(res => {
      this.veboniOrder = res.MobileHotelDefinition.VeboniOrder;
    }).catch(err => {
      this.veboniOrder = false;
    });

    this.storageService.getItem("selectedDepartment").then(res => {
      this.selectedDepartment = res;
      console.table(res);
      this.tableList = this.selectedDepartment.Tables
    }).catch(err => {
      this.selectedDepartment = null
      this.tableList = []
    })
    this.storageService.getItem("hotelId").then(res => {
      this.hotelID = res
    })
    this.storageService.getItem("reservationId").then(res => {
      this.reservationID = res;
    })
    this.storageService.getItem("guestId").then(res => {
      this.guestID = res;
    })
    this.storageService.getItem("profile").then(profile => {
      this.GuestFullName = profile.firstname + ' ' + profile.lastname
    })
    this.storageService.getItem("LangID").then(langID => {
      this.LangID = this.TranslatingService.languageSelector(langID)
    })

  }
  dismiss() {
    this.modalController.dismiss({
      'dismissed': true
    });
  }
  addToBasket(item) {
    console.log(item, "itemmmmmmm")
    var check = false;
    if (this.basketArray.length > 0) {
      for (var i = 0; i < this.basketArray.length; i++) {
        if (this.basketArray[i].RecId == item.RecId) {
          this.basketArray[i].quantity += 1;
          this.calculateTotalPrice()
          this.storageService.setBasketToCache(this.basketArray)
          check = true;
          console.log(this.basketArray)
          return;
        } else {
          check = false
        }
      }
      if (!check) {
        this.basketArray.push({ Name: item.Name, quantity: 1, Par4: item.Par4, par3: item.par3, RecId: item.RecId, imgurl: item.imgurl, par1: item.par1, ProductSourceId: item.ProductSourceId })
        this.calculateTotalPrice()
        this.storageService.setBasketToCache(this.basketArray)
        console.log(this.basketArray)
      }
    } else {
      this.basketArray.push({ Name: item.Name, quantity: 1, Par4: item.Par4, par3: item.par3, RecId: item.RecId, imgurl: item.imgurl, par1: item.par1, ProductSourceId: item.ProductSourceId })
      this.calculateTotalPrice()
      this.storageService.setBasketToCache(this.basketArray)
      console.log(this.basketArray)
    }

    this.storageService.setBasketToCache(this.basketArray)
    console.log(this.basketArray)
  }
  decreaseFromBasket(item) {
    for (var i = 0; i < this.basketArray.length; i++) {
      if (this.basketArray[i].RecId == item.RecId) {
        if (this.basketArray[i].quantity > 0) {
          this.basketArray[i].quantity -= 1;
          this.calculateTotalPrice()
          this.storageService.setBasketToCache(this.basketArray)
          if (this.basketArray.length == 0) {
            this.dismiss()
          }
          if (this.basketArray[i].quantity == 0) {
            this.basketArray.splice(this.basketArray.indexOf(item), 1);
            this.calculateTotalPrice()
            this.storageService.setBasketToCache(this.basketArray)
            if (this.basketArray.length == 0) {
              this.dismiss()
            }
          }
        } else {
          this.basketArray.splice(this.basketArray.indexOf(item), 1);
          this.calculateTotalPrice()
          this.storageService.setBasketToCache(this.basketArray)
          if (this.basketArray.length == 0) {
            this.dismiss()
          }
        }
      }
    }
  }

  calculateTotalPrice() {
    var totalPrice = 0;
    for (var i = 0; i < this.basketArray.length; i++) {
      totalPrice = this.basketArray[i].quantity * this.basketArray[i].Par4 + totalPrice;
    }
    return totalPrice;
  }
  selectTable(table) {
    console.log("table: ", this.selectedTable)

  }
  onFocus() {
    this.backButtonBoolean = true
    this.shared.updateBottomTabBarVisibility(this.backButtonBoolean);
  }
  outFocus() {
    this.backButtonBoolean = false
    this.shared.updateBottomTabBarVisibility(this.backButtonBoolean);
  }
  completePayment() {
    this.loading.show().then(res => {
      var today = new Date()
      this.storageService.getItem("selectedDepartment").then(res => {
        console.table(res);
        console.table(this.basketArray);
        this.postOrderObject.DepartmentId = res.RecId

        this.postOrderObject.DepartmentSourceId = res.DepartmentSourceId && res.DepartmentSourceId !== undefined ? res.DepartmentSourceId : null

        this.postOrderObject.RecId = this.hotelID
        this.postOrderObject.GuestId = this.guestID
        this.postOrderObject.ReservationId = this.reservationID
        this.postOrderObject.Note = this.orderNote
        this.postOrderObject.GuestFullName = this.GuestFullName
        this.postOrderObject.LangCode = this.LangID
        for (var i = 0; i < this.basketArray.length; i++) {
          const isVeboni = 
          (this.basketArray[i].ProductSourceId !== "00000000-0000-0000-0000-000000000000" && this.basketArray[i].ProductSourceId !== undefined) ? true : false;

          this.postOrderObject.Body.Products.push(
            {
              "ProductId": this.basketArray[i].par1,
              "ProductGuidId": (isVeboni ? this.basketArray[i].ProductSourceId : "00000000-0000-0000-0000-000000000000"),
              "TableId": (isVeboni ? 0 : parseInt(this.selectedTable)),
              "TableGuidId": (isVeboni ? this.selectedTable : "00000000-0000-0000-0000-000000000000"),
              "DepartGuidId": (isVeboni ? this.postOrderObject.DepartmentSourceId : "00000000-0000-0000-0000-000000000000"),
              "GuestId": this.guestID,
              "ReservationId": this.reservationID,
              "OrderDate": today,
              "Deleted": 0,
              "Transfered": 0,
              "Price": this.basketArray[i].Par4,
              "CurrencyCode": this.basketArray[i].par3,
              "OrderId": null,
              "CompanyRecId": 10,
              "Amount": this.basketArray[i].quantity,
              "ProductRemark": this.basketArray[i].Name,
              "Note": this.orderNote
            })
        }

        console.log("postorderobject ", this.postOrderObject)

        this.http.post("v1/SednaMobileB2C/PostOrderFromPms", this.postOrderObject).then(res => {
          if (res.ResponseType == "Ok") {





            this.translate.get("orderSaved").subscribe(res => {
              this.toastr.toast(res, "success", 2000)
              this.loading.hide()
            })
            this.basketArray = []
            this.storageService.setBasketToCache(this.basketArray)
            if (this.basketArray.length == 0) {
              this.dismiss()
            }
          } else {
            this.translate.get("orderSaveFail").subscribe(res => {
              this.toastr.toast(res, "fail", 2000)
              this.loading.hide()
            })
          }
        })
      })

    })

  }
  presentModal(modalType) {
    this.modalControllerService.presentModal(modalType, undefined);
  }
}
