
import { Injectable, Inject } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { StorageService } from './storage.service';

@Injectable({
  providedIn: 'root'
})
export class ThemeService {

  constructor(@Inject(DOCUMENT) private document: Document,
    private storageService: StorageService) { }


  setTheme(): Promise<any> {
    return this.storageService.getItem("themeColor").then(res => {
      if (res == document.documentElement.style.getPropertyValue("--myBackground")) {
        return true
      } else {
        document.documentElement.style.setProperty("--myBackground", res);
        return true
      }
    }).catch(res => {
      alert("themecolor hatası")
    })


  }
}
