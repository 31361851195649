
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { URLactivity } from '../models/activityURLModel';
import { dashboardURL } from '../models/dashboardURLModel';
import { DashboardTypes } from '../models/enums/dashboard-types.enum';
import { MenuTypes } from '../models/enums/menu-types.enum';
import { menuLeftImageModel } from '../models/menuLeftImageModel';
import { redirectModel } from '../models/redirectModel';
import { userActivitiesModel } from '../models/userActivityModel';
import { MobileMenuService } from './mobile-menu.service';
import { SharedService } from './shared.service';
import { StorageService } from './storage.service';
import { ThemeService } from './theme.service';
import { TranslatingService } from './translate.service';
import { EnumLanguageType } from '../models/enums/enum-language-type.enum';
import { ModalControllerService } from './modalController.service';
import { ModalTpyes } from '../models/enums/modal-types.enum';
import { InAppBrowser } from '@ionic-native/in-app-browser/ngx';
import { AlertController } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import { ReservationService } from './reservation.service';
import { SettingsURL } from '../models/settingsURLModel';

@Injectable({
  providedIn: 'root'
})
export class RouteLinkParametersService {
  dashboardURLModel: dashboardURL = { HotelId: 0, GroupId: 0, PageTitle: "Dashboard", LangID: 1 };
  settingsURLModel: SettingsURL = { HotelId: 0, GroupId: 0, PageTitle: "Ayarlar", LangID: 1 };
  activityURLModel: URLactivity = { HotelId: 0, GroupId: 0, KioskId: "", ActivityType: 0, ReservationId: "", PageTitle: "Restoranlar", LangID: 1 };
  menuItemName
  userActivityModel: userActivitiesModel;
  traceListModel: any;
  deviceLanguage: EnumLanguageType; //import("c:/Users/Emre/Desktop/SednaMobile/src/app/models/enums/enum-language-type.enum").EnumLanguageType;
  noButton: any;
  yesButton: any;
  promtMessage: any;
  constructor(private storageService: StorageService,
    public alertController: AlertController,
    private router: Router,
    private modalControllerService: ModalControllerService,
    private translate: TranslatingService,
    private mobileMenuService: MobileMenuService,
    private themeService: ThemeService,
    private InAppBrowser: InAppBrowser,
    private translateService: TranslateService,
    private shared: SharedService,
    private reservationService: ReservationService) {
  }

  checkNecessaryHotelData(): Promise<any> {
    var myurl = window.location.href.toString()
    return this.storageService.getItem("anaMenu").then(res => {
      var hotelIDCheck: any = /HotelId=([^;]+)/.exec(myurl)[1];
      var grupID: any = /GroupId=([^;]+)/.exec(myurl)[1];

      if (res == null) {
        var x = false
        return this.translate.getDeviceLanguage().then(lang => {
          return this.mobileMenuService.getMobileMenu(parseInt(hotelIDCheck), parseInt(grupID), lang).then(res => {
            res.MobileHotelDefinition.checkLocalStorageAnaMenu = x
            this.storageService.setBaseInfosToCache(res).then(res => {
              this.translate.switchLanguage(lang)
              return this.themeService.setTheme().then(res => {
                return true;
              }).catch(err => {
                console.log('An error was accoured while setTheme()', err);
              })
            }).catch(err => {
              console.log('An error was accoured while setBaseInfosToCache()', err);
            })
          }).catch(err => {
            console.log('An error was accoured while getMobileMenu()', err);
          })
        })
      }
      if (/HotelId=([^;]+)/.exec(myurl) != null) {
        var hotelIDCheck: any = /HotelId=([^;]+)/.exec(myurl)[1];
        console.log("hotelIDCheck: ", hotelIDCheck)
        console.log("hotelIDCheck: ", res.MobileHotelDefinition.RecId)
        if (res.MobileHotelDefinition.RecId == parseInt(hotelIDCheck)) {
          if (res) {
            console.log("data var")
          } else {
            console.log("data yok")

            //url deki istediğimiz değişkenin değerini almamızı sağlıyo

            var hotelID: any = /HotelId=([^;]+)/.exec(myurl)[1];
            var hotelIDResult = hotelID ? hotelID : 'myDefaultValue';

            var grupID: any = /GroupId=([^;]+)/.exec(myurl)[1];
            var grupIDResult = grupID ? grupID : 'myDefaultValue';


            return this.translate.getDeviceLanguage().then(LANG => {
              return this.mobileMenuService.getMobileMenu(parseInt(hotelID), parseInt(grupID), LANG).then(res => {
                // alert(JSON.stringify("getMobileMenu "+res))
                this.storageService.setBaseInfosToCache(res).then(res => {
                  this.translate.switchLanguage(LANG)
                  return this.themeService.setTheme().then(res => {
                    return true;
                  })
                })
              }).catch(err => {
                //alert("getmobilemenu hatası")
              })
            })
          }
        } else {
          console.log("data yok")

          //url deki istediğimiz değişkenin değerini almamızı sağlıyo


          var hotelID: any = /HotelId=([^;]+)/.exec(myurl)[1];
          var hotelIDResult = hotelID ? hotelID : 'myDefaultValue';

          var grupID: any = /GroupId=([^;]+)/.exec(myurl)[1];
          var grupIDResult = grupID ? grupID : 'myDefaultValue';


          return this.translate.getDeviceLanguage().then(LANG => {
            return this.mobileMenuService.getMobileMenu(parseInt(hotelID), parseInt(grupID), LANG).then(res => {
              // alert(JSON.stringify("getMobileMenu "+res))
              this.storageService.setBaseInfosToCache(res).then(res => {
                this.translate.switchLanguage(LANG)
                return this.themeService.setTheme().then(res => {
                  return true;
                })
              })
            }).catch(err => {
              //alert("getmobilemenu hatası")
            })
          })
        } //eğer qr koddan okutuyosa ve farklı otellere girip çıkıyosa, cachten diğer otelin bilgilerini görmesin cache yenilensin diye yapıldı
      }

    }).catch(res => {
      //url deki istediğimiz değişkenin değerini almamızı sağlıyo
      //debugger
      var myurl = window.location.href.toString()
      var hotelID: any = /HotelId=([^;]+)/.exec(myurl)[1];
      var hotelIDResult = hotelID ? hotelID : 'myDefaultValue';

      var grupID: any = /GroupId=([^;]+)/.exec(myurl)[1];
      var grupIDResult = grupID ? grupID : 'myDefaultValue';
      var x = true;

      return this.translate.getDeviceLanguage().then(lang => {
        return this.mobileMenuService.getMobileMenu(parseInt(hotelID), parseInt(grupID), lang).then(res => {
          res.MobileHotelDefinition.checkLocalStorageAnaMenu = x
          this.storageService.setBaseInfosToCache(res).then(res => {
            this.translate.switchLanguage(lang)
            return this.themeService.setTheme().then(res => {
              return true;
            }).catch(err => {
              console.log('An error was accoured while setTheme()', err);
            })
          }).catch(err => {
            console.log('An error was accoured while setBaseInfosToCache()', err);
          })
        }).catch(err => {
          console.log('An error was accoured while getMobileMenu()', err);
        })
      })
    })
  }

  redirectFromDashboard(id: DashboardTypes) {
    switch (id) {
      case 1:
        this.FillDataOfPageForDashboard(id).then(res => {
          this.userActivityModel = res
          this.router.navigate(['/useractivities', this.userActivityModel])
        })
        break;

      case 2:
        this.FillDataOfPageForDashboard(id).then(res => {
          this.traceListModel = res
          this.router.navigate(['/trace-list', this.traceListModel])
        })
        break;

      case 3:
        this.FillDataOfPageForDashboard(id).then(res => {
          this.traceListModel = res
          this.router.navigate(['/balance', this.traceListModel])
        })
        break;

      case 4:
        this.FillDataOfPageForDashboard(id).then(res => {
          this.traceListModel = res
          this.router.navigate(['/loyalty', this.traceListModel])
        })
        break;

      case 5:
        this.FillDataOfPageForDashboard(id).then(res => {
          this.traceListModel = res
          this.router.navigate(['/accommodations', this.traceListModel])
        })
        break;
      case 6:
        this.FillDataOfPageForDashboard(id).then(res => {
          this.traceListModel = res
          this.router.navigate(['/mobile-order-list', this.traceListModel])
        })
        break;

      default:
        break;
    }
  }
  presentIframePage(iframeType: ModalTpyes): Promise<any> {
    // alert("iframeType -> " + iframeType)
    return this.modalControllerService.presentModal(iframeType, undefined);
  }
  redirectViaPageType(redirectObj: redirectModel, item) {
    // alert(JSON.stringify(redirectObj) + JSON.stringify(item))
    if (redirectObj) {
      switch (redirectObj.PageType) {


        case MenuTypes.sss:
          this.FillDataOfPage(redirectObj.PageType, {}).then(res => {
            redirectObj.HotelId = res.HotelId
            redirectObj.GroupId = res.GroupId;
            this.shared.updateSSS(item)
            this.router.navigate(["/sss", redirectObj])
          })
          break;

        case MenuTypes.singleActivity:
          this.FillDataOfPage(redirectObj.PageType, {}).then(res => {
            redirectObj.HotelId = res.HotelId
            redirectObj.GroupId = res.GroupId;
            redirectObj["CompanyRecId"] = res.CompanyId
            this.storageService.getItem("ActivityType").then(res => {
              redirectObj["ActivityType"] = res
              this.storageService.getItem("KioskID").then(res => {
                redirectObj["KioskId"] = res
                this.router.navigate(["/reservation", redirectObj])
              })
            })
          })
          break;
        case MenuTypes.departmanDoubleMenu:
          this.FillDataOfPage(redirectObj.PageType, {}).then(res => {
            redirectObj.HotelId = res.HotelId;
            redirectObj.GroupId = res.GroupId;
            redirectObj["Type"] = MenuTypes.departmanDoubleMenu;
            this.reservationService.reservationStatusCheck(redirectObj.PageType);
            this.router.navigate(["/doubled-menu", redirectObj])
          })
          break;


        case MenuTypes.sedna360Survey:
          this.shared.updateIframeLink(item)
          this.presentIframePage(ModalTpyes.sedna360Survey)
          break;

        case MenuTypes.PDF:
          this.shared.updateIframeLink(item);
          this.presentIframePage(ModalTpyes.pdf);
          break;

        case MenuTypes.preCheckinLink:
          this.shared.updateIframeLink(item)
          this.presentIframePage(ModalTpyes.preCheckinLink)
          break;

        case MenuTypes.internetLink:

          console.log("redirect link", item)
          // _self: Opens in the Cordova WebView if the URL is in the white list, otherwise it opens in the InAppBrowser.
          // _blank: Opens in the InAppBrowser.
          // _system: Opens in the system's web browser.

          this.InAppBrowser.create(item, '_system');


          // window.open(item, '_blank');
          break;

        case MenuTypes.gallery:
          console.log("galeri full screen için", item)
          this.FillDataOfPage(redirectObj.PageType, {}).then(res => {
            redirectObj.HotelId = res.HotelId;
            redirectObj.GroupId = res.GroupId;
            redirectObj.LangID = res.LangID;
            this.router.navigate(["/galery", redirectObj])
          })
          break;

        case MenuTypes.activity:
          this.FillDataOfPage(redirectObj.PageType, item).then(res => {
            if (res == undefined) {

            } else {
              this.reservationService.reservationStatusCheck(redirectObj.PageType);
              this.router.navigate(["/activity", this.activityURLModel])
            }

          })
          break;

        case MenuTypes.menuLeftImage:
          this.FillDataOfPage(redirectObj.PageType, {}).then(res => {
            redirectObj.HotelId = res.HotelId;
            redirectObj.GroupId = res.GroupId;
            redirectObj.LangID = res.LangID;
            this.reservationService.reservationStatusCheck(redirectObj.PageType);
            this.router.navigate(["/menu-left-image", redirectObj])
          })
          break;
        case MenuTypes.periodicMenu:
          this.FillDataOfPage(redirectObj.PageType, {}).then(res => {
            redirectObj.HotelId = res.HotelId;
            redirectObj.GroupId = res.GroupId;
            redirectObj["periodic"] = 19;
            redirectObj.LangID = res.LangID;
            //periodic tip "programlar" için kullanılmış; programların saati ve hangi günler o program var onu göstermek için burda periodic ayrımını yapmam gerekti. Y.E

            this.router.navigate(["/menu-left-image", redirectObj])
          })
          break;


        case MenuTypes.detailMenu:
          this.FillDataOfPage(redirectObj.PageType, {}).then(res => {
            redirectObj.HotelId = res.HotelId;
            redirectObj.GroupId = res.GroupId;
            if (redirectObj.LangID == undefined) {
              redirectObj.LangID = res.LangID
            }
            //aradaki gereksiz detail menu sayfasını göstermemek için yazıldı, şimdi artık programlarda tıklayınca direk detay sayfasına gidiyoruz. Y.E
            this.mobileMenuService.getMenuDetails(redirectObj.ParentId, redirectObj.LangID).then(response => {
              this.FillDataOfPage(response[0].Type, {}).then(resultDetail => {
                redirectObj.HotelId = resultDetail.HotelId;
                redirectObj.GroupId = resultDetail.GroupId;
                this.router.navigate(["/detail", redirectObj]);
              })
            })
          });
          break;

        case MenuTypes.html:
        case MenuTypes.detail:
          this.FillDataOfPage(redirectObj.PageType, {}).then(res => {
            redirectObj.HotelId = res.HotelId;
            redirectObj.GroupId = res.GroupId;
            console.log("detay için redirect obj", redirectObj)
            this.router.navigate(["/detail", redirectObj]);
          });
          break;

        // case MenuTypes.html:
        //   this.FillDataOfPage(redirectObj.PageType, {}).then(res => {
        //     redirectObj.HotelId = res.HotelId;
        //     redirectObj.GroupId = res.GroupId;
        //     console.log("detay için redirect obj", redirectObj)
        //     this.router.navigate(["/html", redirectObj]);
        //   });
        //   break;

        case MenuTypes.doubledMenu:
          this.FillDataOfPage(redirectObj.PageType, {}).then(res => {
            redirectObj.HotelId = res.HotelId;
            redirectObj.GroupId = res.GroupId;
            redirectObj.LangID = res.LangID;
            this.reservationService.reservationStatusCheck(redirectObj.PageType);
            this.router.navigate(["/doubled-menu", redirectObj]);
          });
          break;

        case MenuTypes.menuWithImages:
          this.FillDataOfPage(redirectObj.PageType, {}).then(res => {
            redirectObj.HotelId = res.HotelId;
            redirectObj.GroupId = res.GroupId;
            this.router.navigate(["/menu-with-images", redirectObj]);
          });
          break;

        case MenuTypes.productList:

          this.FillDataOfPage(redirectObj.PageType, {}).then(res => {
            redirectObj.HotelId = res.HotelId;
            redirectObj.GroupId = res.GroupId;
            redirectObj["departmentID"] = redirectObj.DepartmentID
            this.reservationService.reservationStatusCheck(redirectObj.PageType);
            this.router.navigate(['/product-list', redirectObj]);
          });
          break;

        case MenuTypes.task:
          this.FillDataOfPage(redirectObj.PageType, {}).then(res => {
            if (res == undefined) {

            } else {
              console.log(res)
              redirectObj.HotelId = res.HotelId;
              redirectObj.GroupId = res.GroupId;
              console.log(res)
              redirectObj.HotelId = res.HotelId;
              redirectObj.GroupId = res.GroupId;
              this.reservationService.reservationStatusCheck(redirectObj.PageType);
              this.router.navigate(['/task', redirectObj]);
            }
          });

          break;
        case MenuTypes.transfer:
          this.FillDataOfPage(redirectObj.PageType, {}).then(res => {
            redirectObj.HotelId = res.HotelId;
            redirectObj.GroupId = res.GroupId;
            // redirectObj["departmentID"] = redirectObj.DepartmentID
            this.router.navigate(['/transfer', redirectObj]);

          });
          break;

        case MenuTypes.tourticket:
          this.FillDataOfPage(redirectObj.PageType, {}).then(res => {
            redirectObj.HotelId = res.HotelId;
            redirectObj.GroupId = res.GroupId;
            // redirectObj["departmentID"] = redirectObj.DepartmentID
            this.router.navigate(['/tour-ticket', redirectObj]);

          });
          break;

        default:
          break;
      }
    }

  }



  getRouteLinkParameters(page: string, data) {
    console.log("asd")
    switch (page) {
      case "/dashboard":
        this.storageService.getItem("hotelId").then(res => {
          this.dashboardURLModel.HotelId = res
          this.storageService.getItem("groupId").then(res => {
            this.dashboardURLModel.GroupId = res
            this.router.navigate([page, this.dashboardURLModel])
          });
        });
        break;

      case "/qr":
        this.router.navigate(["/barcode-scanner"])
        break;

      case "/login":
        this.router.navigate([page])
        break;

      case "/activity":
        if (data != "undefined") {
          this.router.navigate([page, data])
        } else {
          this.FillDataOfPage(MenuTypes.activity, "undefined").then(res => {
            console.log(res, "undefined?")
            this.router.navigate([page, res])
          })
        }
        break;

      case "/ayarlar":
        this.storageService.getItem("hotelId").then(res => {
          this.settingsURLModel.HotelId = res
          this.storageService.getItem("groupId").then(res => {
            this.settingsURLModel.GroupId = res
            this.router.navigate([page, this.settingsURLModel])
          });
        });
        break;
      case "/precheckin":
        this.router.navigate([page, data])
      default:
        break;
    }
  }
  FillDataOfPageForDashboard(dashboardTypes): Promise<any> {


    if (dashboardTypes == DashboardTypes.orderList) {
      var orderListRequest = { CompanyId: "", HotelId: 0, GroupId: 0 };
      return this.storageService.getItem("hotelId").then(res => {
        orderListRequest.HotelId = res;
        return this.storageService.getItem("groupId").then(res => {
          orderListRequest.GroupId = res;
          return this.storageService.getItem("anaMenu").then(res => {
            orderListRequest.CompanyId = res.MobileHotelDefinition.CompaniesId
            return orderListRequest;
          })
        })
      })
    }


    if (dashboardTypes == DashboardTypes.reservationList) {
      var data = { HotelId: 0, GroupId: 0 };
      return this.storageService.getItem("hotelId").then(res => {
        data.HotelId = res;
        return this.storageService.getItem("groupId").then(res => {
          data.GroupId = res
          return data;
        })
      })
    }

    if (dashboardTypes == DashboardTypes.balance) {
      var balance = { HotelId: 0, GroupId: 0 };
      return this.storageService.getItem("hotelId").then(res => {
        balance.HotelId = res;
        return this.storageService.getItem("groupId").then(res => {
          balance.GroupId = res;
          return balance;
        })
      })
    }

    if (dashboardTypes == DashboardTypes.loyalty || dashboardTypes == DashboardTypes.oldAccommodationsList) {
      var dataLoyalty = { HotelId: 0, GroupId: 0 };
      return this.storageService.getItem("hotelId").then(res => {
        dataLoyalty.HotelId = res;
        return this.storageService.getItem("groupId").then(res => {
          dataLoyalty.GroupId = res
          return dataLoyalty
        })
      })
    }

    if (dashboardTypes == DashboardTypes.traceList) {
      var dataTraceList = { HotelId: 0, GroupId: 0 };
      return this.storageService.getItem("hotelId").then(res => {
        dataTraceList.HotelId = res;
        return this.storageService.getItem("groupId").then(res => {
          dataTraceList.GroupId = res
          return dataTraceList;
        })
      })
    }
  }
  FillDataOfPage(menuType: MenuTypes, item): Promise<any> {
    var data = {};

    if (menuType == MenuTypes.sss) {
      var sssModel = { HotelId: 0, GroupId: 0 };
      return this.storageService.getItem("hotelId").then(res => {
        sssModel.HotelId = res
        return this.storageService.getItem("groupId").then(res => {
          sssModel.GroupId = res
          return sssModel;
        })
      })
    }

    if (menuType == MenuTypes.singleActivity) {
      var singleActivityModel = { CompanyId: "", HotelId: 0, GroupId: 0, ActivityType: 0, KioskId: "" };
      return this.storageService.getItem("anaMenu").then(res => {
        singleActivityModel.CompanyId = res.MobileHotelDefinition.CompanyId
        return this.storageService.getItem("hotelId").then(res => {
          singleActivityModel.HotelId = res
          return this.storageService.getItem("groupId").then(res => {
            singleActivityModel.GroupId = res
            return singleActivityModel
          })
        })
      })
    }
    if (menuType == MenuTypes.activity) {

      return this.storageService.getItem("hotelId").then(res => {
        this.activityURLModel.HotelId = res
        this.activityURLModel.ActivityType = 1
        if (item == "undefined") {
          return this.storageService.getItem("AlacarteId").then(res => {
            this.activityURLModel.KioskId = res;
            this.translateService.get("reservationPage.reservation").subscribe(reservationTranslate => {
              this.activityURLModel.PageTitle = reservationTranslate
            })
            this.storageService.setItem("KioskID", res)
            this.storageService.setItem("ActivityType", 1)
            return this.storageService.getItem("groupId").then(res => {
              this.activityURLModel.GroupId = res;
              console.log(this.activityURLModel, "activity url data request")
              return data = this.activityURLModel;
            })
          })
        }
        if (item.Par4 == 1 || item.Par4 == 4) {
          console.log("alakart")
          return this.storageService.getItem("AlacarteId").then(res => {
            this.activityURLModel.KioskId = res;
            this.activityURLModel.PageTitle = item.Name
            this.storageService.setItem("KioskID", res)
            this.storageService.setItem("ActivityType", 1)
            console.log(this.activityURLModel, "activity url data request")
            return data = this.activityURLModel;
          })
        }
        if (item.Par4 == 2 || item.Par4 == 5) {
          console.log("PavilionId")
          this.activityURLModel.ActivityType = 2
          this.activityURLModel.PageTitle = item.Name
          return this.storageService.getItem("PavilionId").then(res => {
            this.activityURLModel.KioskId = res;
            this.storageService.setItem("KioskID", res)
            this.storageService.setItem("ActivityType", 2)
            console.log(this.activityURLModel, "activity url data request")
            return data = this.activityURLModel;
          }).catch(err => {
            console.log('error getItem("PavilionId"): ', err);

          })
        }
        if (item.Par4 == 3 || item.Par4 == 6) {
          console.log("SpaId")
          this.activityURLModel.ActivityType = 3
          this.activityURLModel.PageTitle = item.Name
          return this.storageService.getItem("SpaId").then(res => {
            this.activityURLModel.KioskId = res;
            this.storageService.setItem("KioskID", res)
            this.storageService.setItem("ActivityType", 3)
            console.log(this.activityURLModel, "activity url data request")
            return data = this.activityURLModel;
          })
        } else {
          console.log("par4 muhtemelen yok :D", item.Par4)
        }
      })
    }

    if (menuType == MenuTypes.menuLeftImage) {
      var obj = new menuLeftImageModel()
      return this.storageService.getItem("hotelId").then(res => {
        obj.HotelId = res
        return this.storageService.getItem("groupId").then(res => {
          obj.GroupId = res
          return this.storageService.getItem("LangID").then(res => {
            obj.LangID = res
            return data = obj
          })
        })
      })
    }

    if (menuType == MenuTypes.periodicMenu) {
      var obj = new menuLeftImageModel()
      return this.storageService.getItem("hotelId").then(res => {
        obj.HotelId = res
        return this.storageService.getItem("groupId").then(res => {
          obj.GroupId = res
          return this.storageService.getItem("LangID").then(res => {
            obj.LangID = res
            return data = obj
          })
        })
      })
    }

    if (menuType == MenuTypes.detail) {
      var obj = new menuLeftImageModel()
      return this.storageService.getItem("hotelId").then(res => {
        obj.HotelId = res
        return this.storageService.getItem("groupId").then(res => {
          obj.GroupId = res
          return data = obj
        })
      })
    }

    if (menuType == MenuTypes.html) {
      var obj = new menuLeftImageModel()
      return this.storageService.getItem("hotelId").then(res => {
        obj.HotelId = res
        return this.storageService.getItem("groupId").then(res => {
          obj.GroupId = res
          return this.storageService.getItem("LangID").then(res => {
            obj.LangID = res
            return data = obj
          })
        })
      })
    }

    if (menuType == MenuTypes.detailHtml) {
      var obj = new menuLeftImageModel();
      return this.storageService.getItem("hotelId").then(res => {
        obj.HotelId = res;
        return this.storageService.getItem("groupId").then(res => {
          obj.GroupId = res;
          return this.storageService.getItem("LangId").then(res => {
            obj.LangID = res;
            return data = obj
          })
        })
      })
    }

    if (menuType == MenuTypes.detailMenu) {
      var obj = new menuLeftImageModel();
      return this.storageService.getItem("hotelId").then(res => {
        obj.HotelId = res
        return this.storageService.getItem("groupId").then(res => {
          obj.GroupId = res
          return data = obj
        })
      })
    }

    if (menuType == MenuTypes.doubledMenu) {
      var obj = new menuLeftImageModel();
      return this.storageService.getItem("hotelId").then(res => {
        obj.HotelId = res
        return this.storageService.getItem("groupId").then(res => {
          obj.GroupId = res
          return this.storageService.getItem("LangID").then(res => {
            obj.LangID = res
            return data = obj
          })
        })
      })
    }

    if (menuType == MenuTypes.menuWithImages) {
      var obj = new menuLeftImageModel();
      return this.storageService.getItem("hotelId").then(res => {
        obj.HotelId = res
        return this.storageService.getItem("groupId").then(res => {
          obj.GroupId = res
          return data = obj
        })
      })
    }

    if (menuType == MenuTypes.gallery) {
      var obj = new menuLeftImageModel();
      return this.storageService.getItem("hotelId").then(res => {
        obj.HotelId = res
        return this.storageService.getItem("groupId").then(res => {
          obj.GroupId = res
          return this.storageService.getItem("LangID").then(res => {
            obj.LangID = res
            return data = obj
          })
        })
      })
    }

    if (menuType == MenuTypes.productList) {
      var obj = new menuLeftImageModel();
      return this.storageService.getItem("hotelId").then(res => {
        obj.HotelId = res
        return this.storageService.getItem("groupId").then(res => {
          obj.GroupId = res
          return data = obj
        })
      })
    }

    if (menuType == MenuTypes.departmanDoubleMenu) {
      var obj = new menuLeftImageModel();
      return this.storageService.getItem("hotelId").then(res => {
        obj.HotelId = res
        return this.storageService.getItem("groupId").then(res => {
          obj.GroupId = res
          return data = obj
        })
      })
    }
    if (menuType == MenuTypes.task) {
      var model = { HotelId: null, GroupId: null };
      return this.storageService.getItem("hotelId").then(res => {
        model.HotelId = res
        return this.storageService.getItem("groupId").then(res => {
          model.GroupId = res
          return data = model
        })
      })
    }
    if (menuType == MenuTypes.transfer) {
      var obj = new menuLeftImageModel();
      return this.storageService.getItem("hotelId").then(res => {
        obj.HotelId = res
        return this.storageService.getItem("groupId").then(res => {
          obj.GroupId = res
          return data = obj
        })
      })
    }
    if (menuType == MenuTypes.tourticket) {
      var obj = new menuLeftImageModel();
      return this.storageService.getItem("hotelId").then(res => {
        obj.HotelId = res
        return this.storageService.getItem("groupId").then(res => {
          obj.GroupId = res
          return data = obj
        })
      })
    }
  }
}

