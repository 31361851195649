import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { Endpoints } from 'src/environments/endpoints';
import { ModalTpyes } from '../models/enums/modal-types.enum';
import { FutureDatesDto } from '../models/reservation/FutureDatesDto';
import { ReservationStatus } from '../models/reservation/reservationStatus';
import { HttpService } from './http.service';
import { ModalControllerService } from './modalController.service';
import { StorageService } from './storage.service';
import { ToasterService } from './toaster.service';

@Injectable({
  providedIn: 'root'
})
export class ReservationService {
  reservationStatusDTO: ReservationStatus = new ReservationStatus();
  resStatusCheck: boolean = false;
  constructor(
    private translate: TranslateService,
    private _httpService: HttpService,
    private storageService: StorageService,
    private toastr: ToasterService,
    private router: Router,
    private modalControllerService: ModalControllerService) { }

  checkTableAvaible(tables: Array<any>): void {
    this.translate.get("reservationPage.available").toPromise().then(available => {
      this.translate.get("reservationPage.notavailable").toPromise().then(notavailable => {
        for (var i = 0; i < tables.length; i++) {
          tables[i]["passControl"] = true;
          tables[i]["message"] = available;
          if (tables[i].Available === 0) {
            tables[i]["passControl"] = false;
            tables[i]["message"] = notavailable;
          }
        }
      }).catch(err => {
        console.log(err);
      })
    }).catch(err => {
      console.log(err)
    })
  }
  checkDateAvailableStatus(futureDates: Array<FutureDatesDto>): void {

    this.translate.get("reservationPage").subscribe(successResult => {
      for (var i = 0; i < futureDates.length; i++) {
        futureDates[i]["passControl"] = false;


        switch (futureDates[i].Available) {
          case 0:
            futureDates[i]["passControl"] = true;
            futureDates[i]["message"] = successResult.free; //'UCRETSİZ';
            break;

          case 1:
            futureDates[i]["passControl"] = false;
            futureDates[i]["message"] = successResult.reserved; //'RESERVE EDİLMİŞ';
            break;
          case 3:
            futureDates[i]["passControl"] = false;
            futureDates[i]["message"] = successResult.cannotbechosen; //'Seçilemez-Gün saatleri dışında';
            break;
        }
        if (futureDates[i].Available === 0) {
          switch (futureDates[i].Open_Price_Control) {
            case 0:
              futureDates[i]["passControl"] = true;
              futureDates[i]["message"] = successResult.free; //'UCRETSİZ';
              break;
            case 1:
              futureDates[i]["passControl"] = true;
              futureDates[i]["message"] = successResult.paid;//'ÜCRETLİ';
              break;
            case 2:
              futureDates[i]["passControl"] = false;
              futureDates[i]["message"] = successResult.cannotbechosen; //'SEÇİLEMEZ';
              break;
            case 3:
              futureDates[i]["passControl"] = false;
              futureDates[i]["message"] = successResult.closed; //'KAPALI';
              break;
            case 4:
              futureDates[i]["passControl"] = false;
              futureDates[i]["message"] = successResult.closed;//'KAPALI';
              break;
            case 5:
              futureDates[i]["passControl"] = false;
              futureDates[i]["message"] = successResult.full;//'DOLU';
              break;
            case 6:
              futureDates[i]["passControl"] = false;
              futureDates[i]["message"] = successResult.closed;//'KAPALI';
              break;
          }
        }
      }
    });



  }

  onChangeDate(dateModel, selectedDate): any {
    console.log("asd")
    var crModel = { DayCR: null, DayChildAge: null }; // TODO : models içindeki DTO lara bağlanacak.(S.Y)
    //var newDate = new Date(selectedDate);
    var newDate = new Date(selectedDate.split('T')[0])
    var numberOfDay = newDate.getDay();
    switch (numberOfDay) {
      case 0:
        crModel.DayCR = dateModel.SundayCR
        crModel.DayChildAge = dateModel.SundayChildAge
        break;

      case 1:
        crModel.DayCR = dateModel.MondayCR
        crModel.DayChildAge = dateModel.MondayChildAge
        break;

      case 2:
        crModel.DayCR = dateModel.TuesdayCR
        crModel.DayChildAge = dateModel.TuesdayChildAge
        break;

      case 3:
        crModel.DayCR = dateModel.WednesdayCR
        crModel.DayChildAge = dateModel.WednesdayChildAge
        break;

      case 4:
        crModel.DayCR = dateModel.ThursdayCR
        crModel.DayChildAge = dateModel.ThursdayChildAge
        break;

      case 5:
        crModel.DayCR = dateModel.FridayCR
        crModel.DayChildAge = dateModel.FridayChildAge
        break;

      case 6:
        crModel.DayCR = dateModel.SaturdayCR
        crModel.DayChildAge = dateModel.SaturdayChildAge
        break;
    }

    return crModel;
  }

  calculatePersonCountToTitle(personList: Array<any>): any {

    var personCountModel = {
      PaxCount: 0,
      BabyCount: 0,
      ChildCount: 0,
      ChildPaidCount: 0,
      ChildFreeCount: 0,
      CalculatedPrice: 0
    };

    personList.forEach(person => {

      switch (person.Title) {
        case 'MR':
        case 'MRS':
        case 'MS':
          personCountModel.PaxCount += 1;
          break;

        case 'INF':
          personCountModel.BabyCount += 1;
          break;

        default:
          if (person.FreeStatus === 0) {
            personCountModel.ChildPaidCount += 1;
          }
          else {
            personCountModel.ChildFreeCount += 1;
          }
          personCountModel.ChildCount += 1;
          break;
      }




    });

    return personCountModel;

  }

  getActivityMenusAndFoods(model: any): Promise<any> {
    return this._httpService.post(Endpoints.Reservation.GetActivityMenusAndFoodsByPms, model);
  }


  reservationStatusCheck(test: any): any {
    return this.storageService.getItem('profile').then(res => {
      if (res != null || res != undefined) {
        this.reservationStatusDTO.Room = res.roomNumber;
        this.reservationStatusDTO.EncryptedReservationId = res.reservationId.toString();
        this.reservationStatusDTO.ResStatus = res.reservationStatus;
        return this._httpService.post(Endpoints.Reservation.ReservationStatusCheck, this.reservationStatusDTO).then(check => {
          if (check != null && check != undefined) {
            if (check.Room != "" || check.Room != null) {
              if (check.Room != res.roomNumber) {
                this.storageService.removeItem('profile');
                this.storageService.setItem('isLogin',false);
                this.toastr.toast("Lütfen oda bilginizi güncelleyiniz.", "fail", 2000);
                //console.log("******************************ODA DEĞİŞMİŞ********************************")
                if (test != 32) {
                  this.presentLoginModal().then(res => { })
                }
              }
            }
          }
        });
      }
    })
  }

  presentLoginModal(): Promise<any> {
    return this.modalControllerService.presentModal(ModalTpyes.login, undefined);
  }
}
