import { Injectable } from '@angular/core';
import { Platform } from '@ionic/angular';

@Injectable({
  providedIn: 'root'
})
export class PlatformDetectionService {

  constructor(private platform: Platform) { }

  platformDetection() {
    // alert(JSON.stringify(this.platform.platforms()))
    if (this.platform.is("mobileweb") || this.platform.is("desktop")) {
      return false;
    } else {
      console.log("mobile değil")
      return true;
    }
  }

  platformDetectionForSplashScreen(){
    if(this.platform.is("android") || this.platform.is("ios")){
      return true;
    }else{
      return false;
    }
  }

  isIOS(){
    if(this.platform.is("ios")){
      return true
    }else{
      return false
    }
  }

  iphoneXOrUpper(width:number,height:number):boolean{
    if((width == 375 && height == 812) ||
       (width == 1125 && height == 2436) ||
       (width == 414 && height == 896) ||
       (width == 828 && height == 1792) ||
       (width == 390 && height == 844) ||
       (width == 1170 && height == 2532)||
       (width == 428 && height == 926) ||
       (width == 1284 && height == 2778) ||
       (width == 360 && height == 780) ||
       (width == 1080 && height == 2340)||
       (width == 1242 && height == 2688)){
       return true;
    }
    return false;
  }
}
