import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { StorageService } from './storage.service';
import { Endpoints } from 'src/environments/endpoints';

@Injectable({
  providedIn: 'root'
})
export class DoorLockLoginCheckService {
  apiAdress: string = environment.apiAddress + "/api/";
  // access_token: any;
  apikey: any;
  companyID: any;
  tokenModel: HttpParams;

  constructor(private httpClient: HttpClient, private storageService: StorageService) { }

  getProfileSearch(model: any): Promise<any> {

    return this.storageService.getItem('anaMenu').then(response => {
      this.apikey = response.MobileHotelDefinition.Sedna360ApiKey;
      this.tokenModel = new HttpParams()
        .set('grant_type', 'delegation')
        .set('scope', 's360api')
        .set('client_id', 'sednahotelapi')
        .set('client_secret', 'secret')
        .set('apikey', this.apikey)

      return this.httpClient.post<any>(environment.getToken, this.tokenModel.toString(), { headers: new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded') })
        .toPromise().then((token: any) => {
          if (token !== 'undefined') {
            let headers = new HttpHeaders();
            if (token) {
              headers = headers.set('Authorization', `Bearer ${token.access_token}`);
            }
            return this.httpClient.post<any>(this.apiAdress + Endpoints.Profile.ProfileSearch, model, { headers: headers }).toPromise();
          }
        });
    }).catch(err => {
      alert(JSON.stringify(err));
    });

  }

  sendActivationCode(model: any): Promise<any> {
    return this.httpClient.post<any>(this.apiAdress + Endpoints.SignUp.SendActivationCode, model).toPromise();
  }

  registerGuest(model: any): Promise<any> {
    return this.httpClient.post<any>(this.apiAdress + Endpoints.Profile.RegisterGuest, model).toPromise();
  }
}
