import { baseModel } from './baseModel';

export class redirectModel extends baseModel  {
    ParentId: number;
    PageType: number;
    GuestId: number;
    ReservationId: number;
    PageTitle: string;
    DepartmentID: number
    DepartmentSourceId: string;

}