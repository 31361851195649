import { Component, OnInit } from '@angular/core';
import { Location } from '@angular/common';
import { ModalController } from '@ionic/angular';
import { StorageService } from 'src/app/services/storage.service';
import { Device } from '@ionic-native/device/ngx';
import { AssaaployService } from 'src/app/services/assaaploy.service';
import doorPlugin from 'src/app/interfaces/IDoorLockPlugin';
import { environment } from 'src/environments/environment';


@Component({
  selector: 'app-door-lock',
  templateUrl: './door-lock.page.html',
  styleUrls: ['./door-lock.page.scss'],
})
export class DoorLockPage implements OnInit {

  companyRecIdAssa: any;
  companyId: any;
  ReservationId;
  guestId: number;
  deviceId: string;
  AssaKey?: string;
  CardId?: string;

  constructor(public modalController: ModalController,
      private storageService: StorageService,
      private device: Device,
      private assaaployService: AssaaployService,) { }

  ngOnInit() {

    this.storageService.getItem("anaMenu").then(resAnaMenu => {
      if (resAnaMenu != null || resAnaMenu != undefined) {
        this.companyRecIdAssa = resAnaMenu.MobileHotelDefinition.CompanyId;
        this.companyId = resAnaMenu.MobileHotelDefinition.CompanyKvkkCondition.CompanyId;
        this.storageService.getItem("profile").then(async resProfile => {
          this.ReservationId = resProfile.reservationId;
          this.guestId = resProfile.guestId;
          this.deviceId = this.device.uuid;

          const localEndpointId = localStorage.getItem('endpointId') ?? '';
          const localApployKey = localStorage.getItem('AssaaployKey');
          const response = await this.assaaployService.getAssaaployKey2(this.companyRecIdAssa, this.ReservationId, this.companyId, this.guestId,resProfile.roomNumber, this.deviceId, localApployKey, localEndpointId).toPromise();
          if (response != null && response != undefined && response.ResponseType === 'Ok') {

            this.storageService.setItem('endpointId', response.Response.EndpointId)
            this.storageService.setItem('AssaaployKey', response.Response.ApployKey)
            this.AssaKey = response.Response.ApployKey;
            this.CardId = response.Response.CardId;

            if (response.message !== undefined && response.message?.indexOf('already exists') !== -1) {
              if (localStorage.getItem('AssaaployKey') !== '' && localStorage.getItem('AssaaployKey') !== undefined && localStorage.getItem('AssaaployKey') !== null) {
                this.AssaKey = localStorage.getItem('AssaaployKey').toString().trim() ?? '';
                //this.AssaKey = this.AssaKey.substring(1, this.AssaKey.length - 1);
              }
            }

            await doorPlugin.doorLock({
              doorLockKey: this.AssaKey,
              url: environment.apiAddress + "/api/v1/SednaMobileB2C/GenerateAssaKeyHotel",
              Token: "",
              ResId: this.ReservationId,
              CompanyRecId: this.companyRecIdAssa,
              EndpointId: this.ReservationId,
              CompanyId: this.companyId,
              GuestId: this.guestId,
              DeviceId: this.deviceId,
              CardId: this.CardId,
              RoomNumber: resProfile.roomNumber
            }

            );
          } else {
            console.log(response);
            alert(response.Message);
          }
        });
      }
    });
  }

  dismiss() {
    this.modalController.dismiss({
      'dismissed': true
    });
  }
}
