import { Component, OnInit } from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { ModalController, Platform } from '@ionic/angular';
import { SharedService } from 'src/app/services/shared.service';
import { ToasterService } from 'src/app/services/toaster.service';

@Component({
  selector: 'app-iframer',
  templateUrl: './iframer.page.html',
  styleUrls: ['./iframer.page.scss'],
})
export class IframerPage implements OnInit {
  link;
  title: string;

  constructor(private shared: SharedService, private domSanitazer: DomSanitizer,
    public modalController: ModalController) { 

      this.shared.iframeLinksTriggerObservable.subscribe(res=>{
        this.title = res.Remark
        this.link = this.domSanitazer.bypassSecurityTrustResourceUrl(res.Link)
      })
    }

  ngOnInit() {
    console.log(this.link)
    // var iframe = document.querySelector("iframe");
    // iframe.src = this.link
    
  }
  dismiss() {
    this.modalController.dismiss({
      'dismissed': true
    });
  }

}
