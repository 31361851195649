import { Injectable } from "@angular/core";
import { HttpService } from "./http.service";
import { StorageService } from './storage.service';

@Injectable({
  providedIn: "root",
})
export class MobileMenuService {
  constructor(private httpService: HttpService,
    private storageService: StorageService) { }

  getMobileMenu(hotelId: number, groupId: number, langID: number): Promise<any> {
    if (langID == null || langID == undefined) {
      //debugger
      return this.httpService.post("v1/SednaMobileB2C/GetMobileHotelMainMenus", {
        HotelId: hotelId,
        GroupId: groupId,
        LanguageId: 1
      });
    } else {
      return this.httpService.post("v1/SednaMobileB2C/GetMobileHotelMainMenus", {
        HotelId: hotelId,
        GroupId: groupId,
        LanguageId: langID
      });
    }
  }

  getCustomerTransfers(guestId : number , hotelId: number, groupId: number):Promise<any>{
    return this.httpService.post("v1/SednaMobileB2C/GetCustomerTransfers", { GuestId: guestId, HotelId: hotelId, GroupId: groupId });
  }
  getCustomerTickets(guestId : number , hotelId: number, groupId: number):Promise<any>{
    return this.httpService.post("v1/SednaMobileB2C/GetCustomerTickets", { GuestId: guestId, HotelId: hotelId, GroupId: groupId });
  }
  getCustomerInfos(guestId : number , hotelId: number, groupId: number):Promise<any>{
    return this.httpService.post("v1/SednaMobileB2C/GetCustomerInfos", { GuestId: guestId, HotelId: hotelId, GroupId: groupId });
  }

  getMenuDetailsForShortCut(parentId: number, langID: number):Promise<any>{
        return this.storageService.getItem("hotelId").then(hotelID => {
          return this.httpService.post("v1/SednaMobileB2C/GetMenuDetailsForShortcut", { ParentId: parentId, LanguageId: langID, HotelId: hotelID });
        }).catch(err => {
        })
  }
  getMenuDetails(parentId: number, langID: number): Promise<any> {
    return this.storageService.getItem("guestId").then(res => {
      if (res != null || res != undefined) {
        return this.storageService.getItem("hotelId").then(hotelID => {
          return this.httpService.post("v1/SednaMobileB2C/GetMenuDetails", { ParentId: parentId, LanguageId: langID, GuestId: res, HotelId: hotelID });
        }).catch(err => {

        })
      } else {
        return this.storageService.getItem("hotelId").then(hotelID => {
          return this.httpService.post("v1/SednaMobileB2C/GetMenuDetails", { ParentId: parentId, LanguageId: langID, HotelId: hotelID });
        }).catch(err => {

        })
      }
    }).catch(err => {
      return this.storageService.getItem("hotelId").then(hotelID => {
        return this.httpService.post("v1/SednaMobileB2C/GetMenuDetails", { ParentId: parentId, LanguageId: langID, HotelId: hotelID });
      }).catch(err => {

      })
    })
  }
}
