// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

// TODO: Local
// TODO: Local

// export const environment = {
//   production: false,
//   apiAddress: "http://localhost:5001",
//   surveyAddress:"http://localhost:5001",
//   PaymentRequestApiAddress: "http://testpayment.kodsedna.com/",
//   getTokenModelApiAddress: "https://testapi.sedna360.com/api/v1/SednaMobileB2C/GetTokenForPaymentApiAsync?apiKey=",
//   getToken: "http://localhost:5000/connect/token",
//   hotelId:93,
//   groupId:35,
//   apiVersion:"2.4.10.87",
//   googleProjectNumber:"291601897927"
// };


// TODO: Test

// export const environment = {
//    production: false,
//    apiAddress: "https://testapi.sedna360.com",
//    surveyAddress:"https://test.sedna360.com",
//    PaymentRequestApiAddress: "http://testpayment.kodsedna.com/",
//    getTokenModelApiAddress: "https://testapi.sedna360.com/api/v1/SednaMobileB2C/GetTokenForPaymentApiAsync?apiKey=",
//    getToken: "https://testidentity.sedna360.com/connect/token",
//    hotelId:93,
//    groupId:35,
//    apiVersion:"2.4.10.87",
//    googleProjectNumber:"291601897927"
// }


// TODO: Production

export const environment = {
  production: true,
  apiAddress: "https://mobileapi.sedna360.com",
  surveyAddress:"https://www.sedna360.com",
  PaymentRequestApiAddress: "https://gateway.sedna360.com/",
  getTokenModelApiAddress: "https://api.sedna360.com/api/v1/SednaMobileB2C/GetTokenForPaymentApiAsync?apiKey=",
  getToken: "https://identity.sedna360.com/connect/token",
  hotelId:93,
  groupId:35,
  apiVersion:"2.4.10.87",
  googleProjectNumber:"291601897927"
}


// Nirvana Cosmopolitan

// export const environment = {
//   production: true,
//   apiAddress: "https://mobileapi.sedna360.com",
//   surveyAddress:"https://www.sedna360.com",
//   PaymentRequestApiAddress: "https://gateway.sedna360.com/",
//   getTokenModelApiAddress: "https://api.sedna360.com/api/v1/SednaMobileB2C/GetTokenForPaymentApiAsync?apiKey=",
//   hotelId:93,
//   groupId:13,
//   apiVersion:"2.4.10.87",
//   googleProjectNumber:"291601897927"
// }



/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
