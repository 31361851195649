import { Component, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { ModalController } from '@ionic/angular';
import { RouteLinkParametersService } from 'src/app/services/route-link-parameters.service';
import { SharedService } from 'src/app/services/shared.service';
import { Location } from '@angular/common';

@Component({
  selector: 'app-screen3-d',
  templateUrl: './screen3-d.page.html',
  styleUrls: ['./screen3-d.page.scss'],
})
export class Screen3DPage implements OnInit {
  threeDHTML: any;

  constructor(private shared: SharedService,
    private domSanitazer: DomSanitizer,
    private routelink: RouteLinkParametersService,
    public modalController: ModalController) {
    this.shared.threeD_HTMLObservable.subscribe(res => {
      this.threeDHTML = this.domSanitazer.bypassSecurityTrustHtml(res)
    })
  }
  ngOnInit() {
    var iframe = document.querySelector("iframe");
    iframe.src = 'data:text/html,' + encodeURIComponent(this.threeDHTML);
    console.log(iframe)
  }
  backClicked() {
    this.modalController.dismiss({
      'dismissed': true
    });
    console.log("modal kapatıldı")
  }
  goToDasboard(){
    this.modalController.dismiss({
      'dismissed': true
    }).then(res=>{
      this.shared.updatePaymentRedirecTrigger("closeCreditCardModal")
      this.shared.updatePaymentRedirecTrigger(null)
      console.log("go to dashboard");
    });
    
    
    
  }
  routeWithTabButton(path: string) {
    console.log("tab button ", path)
    this.modalController.dismiss({
      'dismissed': true
    });
    this.routelink.getRouteLinkParameters(path, "undefined")
  }
}

