import { Injectable } from '@angular/core';
import { Platform } from '@ionic/angular';
import { NativeStorage } from '@ionic-native/native-storage/ngx';
import { SharedService } from './shared.service';

@Injectable({
  providedIn: 'root'
})
export class StorageService {

  constructor(private platform: Platform,
    private nativeStore: NativeStorage, private shared: SharedService) { }


  getItem(key: string): Promise<any> {

    if (window.location.href.match("sedna360mobileb2c")) {
      return Promise.resolve().then(function () {
        return JSON.parse(localStorage.getItem(key));
      });
    } else {
      if (this.platform.is("mobileweb") || this.platform.is("desktop")) {
        // alert("desktop storage girdi")
        return Promise.resolve().then(function () {
          return JSON.parse(localStorage.getItem(key));
        });
      }
      else {
        //  this.nativeStore.keys().then(res=>{
        //    return alert(JSON.stringify("keys: "+res))
        //  })
        return this.nativeStore.getItem(key).then(res => {
          return res
        }).catch(err => {
          //alert("at Key: "+ key+ " native storage getItem Error: "+JSON.stringify(err))    
        })
      }
    }
  }

  //TODO: Any olan tipler yerine model eklenecek ve o tipler dönecek.

  setItem(key: string, value: any): void {
    if (window.location.href.match("sedna360mobileb2c")) {
      localStorage.setItem(key, JSON.stringify(value));
    } else {
      try {
        if (this.platform.is("mobileweb") || this.platform.is("desktop")) {
          localStorage.setItem(key, JSON.stringify(value));
          // alert("set edildi localstorage")
        }
        else {

          this.nativeStore.setItem(key, value).then(res => {
            // alert('mobil setItem:' + JSON.stringify(res))
            // alert(JSON.stringify(this.getItem("anaMenu")))
          }

          ).catch(err => {
            // alert(err)
          });
          // alert("set edildi native")
        }
      } catch (error) {
        console.log("an error was accoured.Message is =", error);
      }
    }
  }

  removeItem(key: string) {
    if (window.location.href.match("sedna360mobileb2c")) {
      localStorage.removeItem(key);
    } else {
      try {
        if (this.platform.is("mobileweb") || this.platform.is("desktop")) {
          localStorage.removeItem(key);
        }
        else {
          this.nativeStore.remove(key)
        }
      } catch (error) {
        console.log("an error was accoured.Message is =", error);
      }
    }
  }

  setBasketToCache(data) {
    //removeItem ios da patlatıyo
    this.setItem("basketCounter", data.length)
    this.getItem("basketCounter").then(res => {

      this.shared.updateData(res)
    })
    this.setItem("basketItems", data)
    this.getItem("basketItems").then(res=>{

    })
  }

  setFastMenuReservationCounterToCache(data) {
    this.setItem("reservationsCounter", data.length)
    this.getItem("reservationsCounter").then(res => {
      this.shared.updateFastMenuReservationCounter(res)
    })
  }
  setFastMenuTraceCounterToCache(data) {
    console.log("storage service traceCounter data", data)
    this.setItem("traceCounter", data.length)
    this.getItem("traceCounter").then(res => {
      console.log("shared service gitmeden önce", res)
      this.shared.updateFastMenuTraceCounter(res)
    }).catch(err => {
      console.log(err)
    })
  }


  setBaseInfosToCache(data): Promise<any> {
    this.setItem("AlacarteId", data.MobileHotelDefinition.AlacarteId)
    this.setItem("PavilionId", data.MobileHotelDefinition.PavilionId)
    this.setItem("SpaId", data.MobileHotelDefinition.SpaId)
    this.setItem("anaMenu", data)
    this.setItem("hasBasket", data.MobileHotelDefinition.HasBasket)
    this.setItem("themeBackgroundColor", data.MobileHotelDefinition.BackgroundPalette)
    this.setItem("themeColor", data.MobileHotelDefinition.AccentPalette)
    this.setItem("loginTypes", data.LoginTypes)
    this.setItem("hotelId", data.MobileHotelDefinition.RecId)
    this.setItem("groupId", data.MobileHotelDefinition.GroupId)
    if (data.MobileHotelDefinition.checkLocalStorageAnaMenu) {
      this.setItem("LangID", data.MobileHotelDefinition.DefaultLanguageId)
    }
    // else{
    //   this.setItem("LangID", data.MobileHotelDefinition.DefaultLanguageId)
    // }
    // this.setItem("LangID", data.MobileHotelDefinition.DefaultLanguageId)
    //console.log('değişti storage.service', data.MobileHotelDefinition.DefaultLanguageId);
    this.setItem("dashboardItems", data.MobileHotelDefinition.Dashboard)
    this.setItem("BottomNavigationBars", data.MobileHotelDefinition.BottomNavigationBars)
    this.setItem("UsedLanguages", data.MobileHotelDefinition.UsedLanguages)
    this.setItem("VirtualPosExist", data.MobileHotelDefinition.VirtualPosExist)
    this.setItem("RatingRemarkCount", data.MobileHotelDefinition.RatingRemarkCount)

    return this.getItem("FavoritePages").then(res => {
      this.shared.updateData("callForAppComponent")

      return this.getItem("profile").then(res => {
        if (res != undefined && res != null) {
          this.setItem("isLogin", true)
        } else {
          this.setItem("isLogin", false)
        }
        return true
      }).catch(err => {
        this.setItem("isLogin", false)
        return true
      })
    }).catch(err => {
      this.setItem("FavoritePages", [])
      this.shared.updateData("callForAppComponent")
      return true
    })
  }
}
