import { Component, Input, OnInit } from '@angular/core';
import { SafeUrl } from '@angular/platform-browser';
import { ModalController } from '@ionic/angular';
import { QRInfo } from 'src/app/models/QRInfo';
import { SharedService } from 'src/app/services/shared.service';

@Component({
  selector: 'app-qrcode',
  templateUrl: './qrcode.page.html',
  styleUrls: ['./qrcode.page.scss'],
})
export class QRCodePage implements OnInit {
  @Input() data;
  title: string;
  qrdata
  public qrCodeDownloadLink: SafeUrl = "";
  base64EncodedData
  decodedData
  constructor(
    private shared: SharedService,
    public modalController: ModalController
  ) {
    this.shared.iframeLinksTriggerObservable.subscribe(response => {
      this.title = response.Remark;
    });
  }

  ngOnInit() {
    console.table(this.data)
  
    this.qrdata = {
      "reservationId" : this.data.ReservationId,
      "guestId" : this.data.GuestId,
      "activityId" : this.data.ActivityId,
      "tableId" : this.data.TableId,
      "companiesId" : this.data.companiesId,
      "groupId" : this.data.groupId,
      "hotelId" : this.data.hotelId
    }
    
    this.qrdata = JSON.stringify(this.qrdata);

    this.base64EncodedData = btoa(this.qrdata);
    const qrCodeUrl = `https://example.com/qrcode/${this.base64EncodedData}`;
    console.log("qrCodeUrl = ", qrCodeUrl)
  }

  decodeUrl(){
    const decodedDataString = atob(this.base64EncodedData);
    this.decodedData = JSON.parse(decodedDataString);
    console.log("decodedData: ", this.decodedData)
  }

  onChangeURL(url: SafeUrl) {
    this.qrCodeDownloadLink = url;
  }

  dismiss() {
    this.modalController.dismiss({
      'dismissed': true
    });
  }
}
